import React, { useState, useEffect } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { useMutation } from '@apollo/client'
import { RESET_PASSWORD } from '../graphql/queries'

import Swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faXTwitter, faTiktok } from '@fortawesome/free-brands-svg-icons'

import { FetchErrorNavbar } from '../components/FetchError'

import '../App.css'


const NO_VALID_PASSWORD = 'la contraseña debe tener 6 o más caracteres'


function ResetPassword() {

	const navigate = useNavigate()

	const URLParams = new URLSearchParams(useLocation().search)

	let token: String | null = null
	const tokenInURLParams = (URLParams.get("token")) as string
	token = (decodeURIComponent(tokenInURLParams))

	const [password, setPassword] = useState<string>('')

	const [ resetPassword, resultResetPassword ] = useMutation(RESET_PASSWORD)

	const [passwordError, setPasswordError] = useState<string>('')
	const [error, setError] = useState<boolean> (false)

	useEffect(() => {
		if (resultResetPassword.data) {
			setPassword('')
			const { value: token } = resultResetPassword.data.resetPassword
			localStorage.setItem('auth-token', token)
			resultResetPassword.client.resetStore()
			navigate("/")
		}
	}, [resultResetPassword.data])

	const swalNotification = (title: String, content: String) => {
		Swal.fire({
			title: title,
			html: `<div><p class='sweetAlert2-myText'>${content}</p></div>`,
			
			showCloseButton: true,
			showConfirmButton: true,
			showCancelButton: false,
			confirmButtonText: 'Continuar',

			iconColor: '#e6e8ec',
			color: '#e6e8ec',
			background: '#000000',
			confirmButtonColor: '#4CAF50',
			cancelButtonColor: '#aaa'
		})
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.value.length <= 30) setPassword(event.target.value)
	}

	const handleSubmit = async () => {
		try {
			if (password.length < 6 && passwordError === '') setPasswordError(NO_VALID_PASSWORD)
			if (password.length >= 6) {
				setPasswordError('')
				await resetPassword({variables: {token, password}})
			}
		} catch (error) {
			if ((error as Error).message === 'invalid or expired token') {
				swalNotification('¡Tiempo expirado!', 'Por favor, vuelve a solicitar un cambio de contraseña.')
			}
			else setError(true)
		}
	}

	if (error === true) return <FetchErrorNavbar />

	return (
		<div className='resetPasswordContainer'>
				<div className="signInUpLogo">
					<img src="../img/logo.png" alt="App logo" />
				</div>

				<h2>Cambia tu contraseña</h2>

				<div className="signInUpInput">
					<input 
						type="password" 
						value={ String(password) } 
						name="resetPassword" 
						id="resetPassword" 
						placeholder="" 
						onChange={ handleChange } 
						required />

					<label 
						htmlFor="resetPassword" 
						className="signInUpLabel">
							Nueva contraseña
					</label>

					<label 
						htmlFor="resetPassword" 
						className={`${passwordError === NO_VALID_PASSWORD ? 'signInUpLabelError' : 'signInUpLabelNotError'}`}>
							{`Error: ${NO_VALID_PASSWORD}`}
					</label>
				</div>

				<div className='signInUpButton' onClick={handleSubmit}><h1>Cambiar contraseña</h1></div>
    </div>
	) 
}

export default ResetPassword