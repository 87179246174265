import React from 'react';

import { Link } from "react-router-dom"

import { MatchesToTest } from "../types"

import { useQuery } from '@apollo/client'
import { GET_MATCHES_BY_USER } from '../graphql/queries'

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode, Scrollbar, Mousewheel } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

import { FetchError } from '../components/FetchError'

import * as routesPaths from '../consts/routesPaths';

import '../App.css';


function AppCompatibilityTestsLayout() {

	const { data: dataMatches, error: errorMatches, loading: loadingMatches } = useQuery(GET_MATCHES_BY_USER)
	const matchesToTests: MatchesToTest[] = dataMatches?.getMatchesToTestsByUser as MatchesToTest[]

	// Ordenar por fecha

	if (loadingMatches) return <div className='app-loading'><h1>Cargando...</h1></div>
	if (errorMatches) return <FetchError />

	const flyer = window.innerWidth <= 1024 ? 'mobil' : 'pc'


  return (
    <div className='content'>

      <div className='content-header'>
        <h1>Matches</h1>
        {/* <p>
					¡Tus matches están listos! Accede para encontrar a tu pareja ideal.
        </p> */}
      </div>

			{matchesToTests.length === 0
        ? <div className='noSwiper'>
						<h1>¡¡No hay matches disponibles!!</h1>
						<h2>Los matches estarán disponibles durante 7 días hábiles desde que se publiquen</h2>
            <div className='noSwiper-content'>
              <p>¡Síguenos en nuestra cuenta de Instagram para enterarte de todo lo que sucede!</p>
              <a href={`https://www.instagram.com/unihell.madrid`} target='_blank' rel='noopener noreferrer'><h3>@unihell.madrid</h3></a>
            </div>
          </div>
				:	<Swiper id='swiperMacthes'
						direction={'vertical'}
						slidesPerView={'auto'}
						autoHeight={true}

						spaceBetween={20}
						
						freeMode={true}
						cssMode={false}

						// scrollbar={{
						//   draggable: true,
						// }}
							
						mousewheel={true}

						modules={[FreeMode, Scrollbar, Mousewheel]}
					>

						{matchesToTests?.map((matchesToTest) => {
							return (
								<SwiperSlide key={matchesToTest.id}>
									<Link to={`${routesPaths.APP_MATCH}?matchesToTest=${encodeURIComponent(JSON.stringify(matchesToTest))}`}>
										<div className='matches'>
											<img src={`../../img/${matchesToTest.testBackground}_${flyer}.png`} alt="" />
											<div className='matches-content'>
												<h3>{matchesToTest.testName}</h3>
												{matchesToTest.testType === 'compatibility'
													? <p>Test de compatibilidad</p>
													: <p>Test de busqueda</p>
												}
											</div>
										</div>
									</Link>
								</SwiperSlide>
							)
						})}
					</Swiper>
			}

			<div className='urlInstagram'>
				<a href={`https://www.instagram.com/unihell.madrid`} target='_blank' rel='noopener noreferrer'><div><div><FontAwesomeIcon className='instagramIcon' icon={faInstagram} size="xl" /></div><h3>@unihell.madrid</h3></div></a>
			</div>

    </div>
  )
}

export default AppCompatibilityTestsLayout
