import React from 'react';

import { createBrowserRouter, RouterProvider } from "react-router-dom"

import SignInUpLayout from './routes/SignInUpLayout'
import ResetPassword from './routes/ResetPassword';

import AppLayout from "./routes/AppLayout"
import AppCompatibilityTestsLayout from "./routes/AppCompatibilityTestsLayout"
import AppCompatibilityTestLayout from "./routes/AppCompatibilityTestLayout"
import AppSearchTestsLayout from './routes/AppSearchTestsLayout'
import AppSearchTestLayout from './routes/AppSearchTestLayout'
import AppMatchesLayout from "./routes/AppMatchesLayout"
import AppMatchLayout from "./routes/AppMatchLayout"
import AppUserSettingsLayout from "./routes/AppUserSettingsLayout"

import PrivacyPolicy from "./routes/TermsAndPolicies/PrivacyPolicy"

import ReactRouterError from './components/ReactRouterError'

import * as routesPaths from './consts/routesPaths'

import './App.css'


const router = createBrowserRouter([
  {
    path: routesPaths.APP,
    element: <AppLayout />,
    errorElement: <ReactRouterError />,
    children: [
      {
      	index: true,
        element: <AppCompatibilityTestsLayout />
      },
      {
      	path: routesPaths.APP_COMPATIBILITY_TESTS,
        element: <AppCompatibilityTestsLayout />
    	},
      {
        path: routesPaths.APP_COMPATIBILITY_TEST,
        element: <AppCompatibilityTestLayout />
      },
      // {
      //   path: routesPaths.APP_SEARCH_TESTS,
      //   element: <AppSearchTestsLayout />
      // },
      // {
      //   path: routesPaths.APP_SEARCH_TEST,
      //   element: <AppSearchTestLayout />
      // },
      {
        path: routesPaths.APP_MATCHES,
        element: <AppMatchesLayout />
      },
      {
        path: routesPaths.APP_MATCH,
        element: <AppMatchLayout />
    	},
      {
        path: routesPaths.APP_SETTINGS,
        element: <AppUserSettingsLayout />
    	}
    ]
  },
  {
    path: routesPaths.LOGIN,
    element: <SignInUpLayout />
  },
  {
    path: routesPaths.RESET_PASSWORD,
    element: <ResetPassword />
  },
  {
    path: routesPaths.PRIVACY_POLICY,
    element: <PrivacyPolicy />
  }
])

export default function App() {
    return <RouterProvider router={ router } />
}