import React from 'react';

import '../App.css';


const FetchError = () => {
	return (
		<div className='app-error'>
			<h1>¡¡Opps... ha ocurrido un error inesperado!!</h1>
			<p>No se ha podido conectar con el servidor. Porfavor, intentalo más tarde.</p>
		</div>
	)
}

const FetchErrorNavbar = () => {
	return (
		<div className='app-error-navbar'>
			<h1>¡¡Opps... ha ocurrido un error inesperado!!</h1>
			<p>No se ha podido conectar con el servidor. Porfavor, intentalo más tarde.</p>
		</div>
	)
}


export { FetchError, FetchErrorNavbar }