import React from 'react';
import ReactDOM from 'react-dom/client';

import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

import App from './App';

import './index.css';

// import { inject } from '@vercel/analytics'
// import { injectSpeedInsights } from '@vercel/speed-insights'


// inject()
// injectSpeedInsights()


const httpLink = createHttpLink({ uri: 'https://unihell-server.vercel.app/' })
// const httpLink = createHttpLink({ uri: 'https://tests-app-back-end.vercel.app/' })

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('auth-token')
  return { headers: { ...headers, authorization: token ? `Bearer ${token}` : "" } }
})

// credentials: 'same-origin',
// credentials: 'include'

const client = new ApolloClient({
  connectToDevTools: true,
	cache: new InMemoryCache(),
  link: authLink.concat(httpLink)
})


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
)


// import reportWebVitals from './reportWebVitals';

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
