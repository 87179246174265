import { gql } from '@apollo/client'


export const SINGIN = gql`
	mutation SignIn($email: String!, $password: String!) {
		signIn(email: $email, password: $password) {
			value
		}
	}
`

export const SINGUP = gql`
	mutation SignUp($email: String!, $password: String!, $username: String!, $instagram: String!, $twitter: String,
					$tiktok: String, $gender: String!, $sexualOrientation: String!, $age: Date!, $ageRangeMin: Int!, 
					$ageRangeMax: Int!, $area: String!, $searchArea: String!) {
		signUp(email: $email, password: $password, username: $username, instagram: $instagram, twitter: $twitter,
			   tiktok: $tiktok, gender: $gender, sexualOrientation: $sexualOrientation, age: $age, ageRangeMin: $ageRangeMin,
			   ageRangeMax: $ageRangeMax, area: $area, searchArea: $searchArea) {
			value
		}
	}
`

export const ACCEPTED_PRIVACY_POLICY = gql`
	mutation AcceptedPrivacyPolicy {
		acceptedPrivacyPolicy
	}
`

export const ACCEPT_PRIVACY_POLICY = gql`
	mutation AcceptPrivacyPolicy {
		acceptPrivacyPolicy {
			id
			idUser
			idPrivacyPolicy
			acceptedDate
		}
	}
`

export const USER = gql`
	query User {
		user {
			id
			username
		}
	}
`

export const GET_USER_SETTINGS = gql`
	query GetUserSettings {
		getUserSettings {
			id
			username
			registrationDate
			email
			instagram
			twitter
			tiktok
			gender
			sexualOrientation
			age
			ageRangeMin
			ageRangeMax
			area
			searchArea
		}
	}
`

export const UPDATE_USER_SETTINGS = gql`
	mutation UpdateUserSettings($email: String!, $instagram: String!, $twitter: String, $tiktok: String,
								$gender: String!, $sexualOrientation: String!, $age: Date!, $ageRangeMin: Int!, 
								$ageRangeMax: Int!, $area: String!, $searchArea: String!) {
		updateUserSettings(email: $email, instagram: $instagram, twitter: $twitter, tiktok: $tiktok, 
						   gender: $gender, sexualOrientation: $sexualOrientation, age: $age, ageRangeMin: $ageRangeMin, 
						   ageRangeMax: $ageRangeMax, area: $area, searchArea: $searchArea) {
			id
			username
			registrationDate
			email
			instagram
			twitter
			tiktok
			gender
			sexualOrientation
			age
			ageRangeMin
			ageRangeMax
			area
			searchArea
		}
	}
`

export const UPDATE_USER_PASSWORD = gql`
	mutation UpdateUserPassword($password: String!, $newPassword: String!) {
		updateUserPassword(password: $password, newPassword: $newPassword) {
			id
			username
		}
	}
`

export const DELETE_USER = gql`
	mutation DeleteUser($password: String!) {
		deleteUser(password: $password) {
			id
			username
			registrationDate
			email
			instagram
			twitter
			tiktok
			gender
			sexualOrientation
			age
			ageRangeMin
			ageRangeMax
			area
			searchArea
		}
	}
`

export const ALLOW_MODIFY_USER = gql`
	mutation AllowModifyUser {
		allowModifyUser
	}
`

export const EMAIL_TO_RESET_PASSWORD = gql`
	mutation EmailToResetPassword($email: String!) {
		emailToResetPassword(email: $email)
	}
`

export const RESET_PASSWORD = gql`
	mutation ResetPassword($token: String!, $password: String!) {
		resetPassword(token: $token, password: $password) {
			value
		}
	}
`


export const GET_COMPATIBILITY_TESTS = gql`
	query GetCompatibilityTests {
		getCompatibilityTestsForUser {
			id
			type
			name
			openingDate
			closingDate
			background
			idQuestionsForTest
			idOtherQuestionsForTest
		}
	}
`

export const GET_SEARCH_TESTS = gql`
	query GetSearchTests {
		getSearchTestsForUser {
			id
			type
			name
			openingDate
			closingDate
			background
			idQuestionsForTest
			idOtherQuestionsForTest
		}
	}
`

export const ALL_TESTS = gql`
	query AllTests {
		getTests {
			id
			name
			openingDate
			closingDate
			background
			idQuestionsForTest
			idOtherQuestionsForTest
		}
	}
`

export const GET_TEST = gql`
	query GetTest($id: Int!) {
		getTest(id: $id) {
			id
			name
			openingDate
			closingDate
			background
			idQuestionsForTest
		}
	}
`

export const GET_QUESTIONS = gql`
	query GetQuestions($id: ID!) {
		getQuestionsForTest(id: $id) {
			id
			numOfQuestions
			questions {
				question
				options
			}
		}
	}
`

// export const GET_ANSWERS_BY_TEST = gql`
// 	query GetAnswersByTest($idTest: Int!) {
// 		getAnswersToTestByTest(idTest: $idTest) {
// 			id
// 			idUser
// 			idTest
// 			answers
// 			status
// 		}
// 	}
// `

export const GET_ANSWERS_BY_USER = gql`
	query GetAnswersByUser {
		getAnswersToTestsByUser {
			id
			idUser
			idTest
			answers
			otherAnswers
			status
		}
	}
`

export const ADD_ANSWERS = gql`
	mutation AddAnswers($idTest: ID!, $answers: [String]!, $otherAnswers: [String]) {
		addAnswersToTest(idTest: $idTest, answers: $answers, otherAnswers: $otherAnswers) {
			id
			idUser
			idTest
			answers
			otherAnswers
			status
		}
	}
`

export const UPDATE_ANSWERS = gql`
	mutation UpdateAnswers($id: ID!, $answers: [String]!, $otherAnswers: [String]) {
		updateAnswersToTest(id: $id, answers: $answers, otherAnswers: $otherAnswers) {
			id
			idUser
			idTest
			answers
			otherAnswers
			status
		}
	}
`

export const GET_MATCHES_BY_USER = gql`
	query GetMatchesByUser {
		getMatchesToTestsByUser {
			id
			idUser
			idTest
			resolutionDate
			testType
			testName
			testBackground
			matches {
				matchUsername
				matchInstagram
				matchTwitter
				matchTiktok
				matchCompatibility
			}
		}
	}
`

