import React from 'react';

import '../../App.css';


function PrivacyPolicy() {

	const PrivacyPolicyPath = '../termsAndPolicies/politicaPrivacidadDatos.pdf'

  return (
		<div className='termsAndPolicies'>
			<embed src={PrivacyPolicyPath} type="application/pdf" width="100%" height="100%" />
		</div>
  )
}

export default PrivacyPolicy