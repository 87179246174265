import React, { useState, useEffect, useCallback } from 'react';

import { useNavigate, Link, Outlet, useLocation  } from "react-router-dom"

import { User } from "../types";

import { useQuery, useMutation } from '@apollo/client'
import { USER, ACCEPTED_PRIVACY_POLICY, ACCEPT_PRIVACY_POLICY } from '../graphql/queries'

import Swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFire, faVials, faFlaskVial, faFileContract, faGear, faArrowRightFromBracket, faUser } from '@fortawesome/free-solid-svg-icons'

import { FetchErrorNavbar } from '../components/FetchError'

import * as routesPaths from '../consts/routesPaths';

import '../App.css';


const privacyPolicyURL = `https://${process.env.REACT_APP_PRIVACY_POLICY_URL}/privacyPolicy`


function AppLayout() {
  // console.log("Entrando en AppLayout") 

  const navigate = useNavigate()
  const location = useLocation()

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleHamburgerClick = () => { if (window.innerWidth <= 1024) setIsMenuOpen(!isMenuOpen) }
  useEffect(() => { if (window.innerWidth > 1024) setIsMenuOpen(true) }, []);

  const { data: dataMe, error: errorMe, loading: loadingMe } = useQuery(USER)
  const me: User = dataMe?.user as User

  const [ acceptedPrivacyPolicy ] = useMutation(ACCEPTED_PRIVACY_POLICY)
  const [ acceptPrivacyPolicy ] = useMutation(ACCEPT_PRIVACY_POLICY)

  // const privacyPolicyURL = `https://${process.env.REACT_APP_PRIVACY_POLICY_URL}/privacyPolicy`
  const privacyPolicyURL = `https://unihell.com/privacyPolicy`

  const isAcceptedPrivacyPolicy = useCallback(async () => {
    try { 
      await acceptedPrivacyPolicy() 
    } 
    catch (error) {
      if ((error as Error).message === 'no acceptedPrivacyPolicy') {
        Swal.fire({
          title: `¡Nueva Política de Privacidad de Datos!`,

          html: `
            <div>
              <p class='sweetAlert2-myText'> Hemos actualizado nuestra Política de Privacidad de Datos, por favor, lea detenidamente y acepte nuestra nueva
              <a class='sweetAlert2-myLink' href=${privacyPolicyURL} target="_blank" rel="noopener noreferrer">Política de Privacidad de Datos</a> 
              para seguir usando nuestra aplicación.</p> 
            </div>
          `,
          
          showCloseButton: false,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: 'Aceptar Política',
          cancelButtonText: 'Denegar Política',

          allowOutsideClick: false,

          iconColor: '#e6e8ec',
          color: '#e6e8ec',
          background: '#000000',
          confirmButtonColor: '#4CAF50',
          cancelButtonColor: '#aaa'
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await acceptPrivacyPolicy()
          }
          else if (result.dismiss === Swal.DismissReason.cancel) {
            localStorage.removeItem('auth-token')
            window.location.reload()
          }
        })


      }
      else { return <FetchErrorNavbar /> }
    }
  }, [acceptedPrivacyPolicy, acceptPrivacyPolicy, privacyPolicyURL])

  useEffect(() => { isAcceptedPrivacyPolicy() }, [isAcceptedPrivacyPolicy])


  if (loadingMe) return <div className='app-loading-navbar'><h1>Cargando...</h1></div>
  if (errorMe) return <FetchErrorNavbar />

  if (!me) {
    navigate(routesPaths.LOGIN)
    return null
  }


  const logout = () => {
    localStorage.removeItem('auth-token')
    // client.resetStore()
    window.location.reload()
  } 


  return (
    <div>
      <section className="app-section">
        <div className={`app-section-navbar ${isMenuOpen ? 'show-menu' : ''}`}>

          <Link to={routesPaths.APP_COMPATIBILITY_TESTS}>
            <div className="app-section-navbar-logo">
              <img src="../img/logo.png" alt="App Logo" />
            </div>
          </Link>
          
          <div className="app-section-navbar-menu">
            <ul>
              <li onClick={handleHamburgerClick}>
                <Link to={routesPaths.APP_COMPATIBILITY_TESTS}>
                  <div className={`app-section-navbar-link ${location.pathname === routesPaths.APP_COMPATIBILITY_TESTS || location.pathname === '/' ? 'app-section-navbar-link-active' : ''}`}>
                    <div className='app-section-navbar-link-icon'><FontAwesomeIcon icon={faVials} size="lg"/></div>
                    <p>Test de compatibilidad</p>
                  </div>
                </Link>
              </li>
              {/* <li onClick={handleHamburgerClick}>
                <Link to={routesPaths.APP_SEARCH_TESTS}>
                  <div className='app-section-navbar-link'>
                    <div className='app-section-navbar-link-icon'><FontAwesomeIcon icon={faFlaskVial} size="lg"/></div>
                    <p>Test de busqueda</p>
                  </div>
                </Link>
              </li> */}
              <li onClick={handleHamburgerClick}>
                <Link to={routesPaths.APP_MATCHES}>
                <div className={`app-section-navbar-link ${location.pathname === routesPaths.APP_MATCHES ? 'app-section-navbar-link-active' : ''}`}>
                    <div className='app-section-navbar-link-icon'><FontAwesomeIcon icon={faFire} size="lg"/></div>
                    <p>Matches</p>
                  </div>
                </Link>
              </li>
              <div className="app-section-navbar-link-mobil">
                <li onClick={handleHamburgerClick}>
                  <Link to={routesPaths.APP_SETTINGS}>
                  <div className={`app-section-navbar-link ${location.pathname === routesPaths.APP_SETTINGS ? 'app-section-navbar-link-active' : ''}`}>
                      <div className='app-section-navbar-link-icon'><FontAwesomeIcon icon={faGear} size="lg"/></div>
                      <p>Ajustes</p>
                    </div>
                  </Link>
                </li>
              </div>
              <div className="app-section-navbar-link-mobil">
                <li onClick={handleHamburgerClick}>
                  <Link to={privacyPolicyURL}>
                  <div className={`app-section-navbar-link`}>
                      <div className='app-section-navbar-link-icon'><FontAwesomeIcon icon={faFileContract} size="lg"/></div>
                      <p>Políticas de Privacidad</p>
                    </div>
                  </Link>
                </li>
              </div>
              <div className="app-section-navbar-link-mobil">
                <li onClick={handleHamburgerClick}>
                  <div onClick={logout}>
                    <div className='app-section-navbar-link'>
                      <div className='app-section-navbar-link-icon'><FontAwesomeIcon icon={faArrowRightFromBracket} size="lg"/></div>
                      <p>Cerrar sesión</p>
                    </div>
                  </div>
                </li>
              </div>
              <div className="app-section-navbar-link-mobil">
                <li onClick={handleHamburgerClick}>
                  <Link to={routesPaths.APP_SETTINGS}>
                    <div className='app-section-navbar-link-user'>
                      <div className='app-section-navbar-link-icon'><FontAwesomeIcon icon={faUser} size="xl"/></div>
                      <p>{me?.username}</p>
                    </div>
                  </Link>
                </li>
              </div>
            </ul>
          </div>

          <div className="app-section-navbar-user">
            <ul>
              <li>
                <Link to={routesPaths.APP_SETTINGS}>
                  <div className={`app-section-navbar-link ${location.pathname === routesPaths.APP_SETTINGS ? 'app-section-navbar-link-active' : ''}`}>
                    <div className='app-section-navbar-link-icon'><FontAwesomeIcon icon={faGear} size="lg"/></div>
                    <p>Ajustes</p>
                  </div>
                </Link>
              </li>
              <li onClick={handleHamburgerClick}>
                <Link to={privacyPolicyURL}>
                <div className={`app-section-navbar-link`}>
                    <div className='app-section-navbar-link-icon'><FontAwesomeIcon icon={faFileContract} size="lg"/></div>
                    <p>Políticas de Privacidad</p>
                  </div>
                </Link>
              </li>
              <li>
                <div onClick={logout}>
                  <div className='app-section-navbar-link'>
                    <div className='app-section-navbar-link-icon'><FontAwesomeIcon icon={faArrowRightFromBracket} size="lg"/></div>
                    <p>Cerrar sesión</p>
                  </div>
                </div>
              </li>
              <li>
                <Link to={routesPaths.APP_SETTINGS}>
                  <div className='app-section-navbar-link-user'>
                    <div className='app-section-navbar-link-icon'><FontAwesomeIcon icon={faUser} size="xl"/></div>
                    <p>{me?.username}</p>
                  </div>
                </Link>
              </li>
            </ul>
          </div>

        </div>

        <div className="app-mobil-navbar"></div>

        <button id="menu-btn" className={`hamburger ${isMenuOpen ? 'open' : ''}`} type="button" onClick={handleHamburgerClick}>
          <span className='hamburger-top'></span>
          <span className='hamburger-middle'></span>
          <span className='hamburger-bottom'></span>
        </button>

        <Link to={routesPaths.APP_COMPATIBILITY_TESTS}>
          <div className="app-mobil-logo">
            <img src="../img/logo.png" alt="App Logo" />
          </div>
        </Link>

        <div className="app-section-content">
          <Outlet />
        </div>  

      </section>
    </div>
  )
}

export default AppLayout
