import React from 'react'

import { useLocation } from 'react-router-dom'

import { Match, MatchesToTest } from "../types"

import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Scrollbar, Mousewheel } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/scrollbar'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram, faXTwitter, faTiktok } from '@fortawesome/free-brands-svg-icons'

import '../App.css'


function AppMatchLayout() {

	const URLParams = new URLSearchParams(useLocation().search)

	let matchesToTest: MatchesToTest | null = null
	const matchesToTestInURLParams = URLParams.get("matchesToTest")
	if (matchesToTestInURLParams) matchesToTest = (JSON.parse(decodeURIComponent(matchesToTestInURLParams))) as MatchesToTest

	const matches: Match[] = matchesToTest?.matches as Match[]
	const sortMatches = [...matches].sort((a, b) => b.matchCompatibility - a.matchCompatibility);

	const flyer = window.innerWidth <= 1024 ? 'mobil' : 'pc'
	

	return (
		<div className='contentAux'>
			<div className='contentAux-header'>
				<label>
					¡¡Para estar atento a FUTUROS TEST no te olvides de SEGUIRNOS en {''}
					<a href={`https://www.instagram.com/unihell.madrid`} target='_blank' rel='noopener noreferrer'>
					@unihell.madrid</a>!!
				</label>
      </div>

			<div className='content'>

				<div className='content-background'>
					<img src={`../../img/${matchesToTest?.testBackground}_${flyer}_test.png`} alt="" />
				</div>

				<div className='content-header content-header-pad'>
					<h1>{`${matchesToTest?.testName}`}</h1>
					{/* <p className='content-header-p-color '>¡¡¡Para estar atento a futuros tests siguenos en @unihell.madrid!!!</p> */}
				</div>

				<div className='tableHeader'>
					<div className='tableHeader-content'>
						<div className='tableHeader-content-match'>
							<p>Match</p>
						</div>
						<div className='tableHeader-content-rrss'>
							<p className='tableHeader-content-rrss-pc'>Enlace directo a Redes Sociales</p>
							<p className='tableHeader-content-rrss-mobil'>Enlace directo a RRSS</p>
						</div>

					</div>
				</div>

				<Swiper id='swiperMatch'
					direction={'vertical'}
					slidesPerView={'auto'}
					autoHeight={true}

					freeMode={true}
					cssMode={false}

					scrollbar={{
						draggable: true,
					}}

					mousewheel={true}

					modules={[FreeMode, Scrollbar, Mousewheel]}
				>
					{sortMatches.length !== 0 && (
						<SwiperSlide key={'unihell'}>
							<div className='match'>
								<h2>{`100%`}</h2>
								
								<div className='results'>
									<div className="result">										
										<a href={`https://www.instagram.com/unihell.madrid/`} target='_blank' rel='noopener noreferrer'>
											<FontAwesomeIcon icon={faInstagram} size="xl" /><p>{'unihell.madrid'}</p>											
										</a>
									</div>
									
									{/* <div className="optionalResult">
										<a href={`https://twitter.com/${match.matchTwitter.toLowerCase()}`} target='_blank' rel='noopener noreferrer'>
											<FontAwesomeIcon icon={faXTwitter} size="xl" /><p>{match.matchTwitter.toLowerCase()}</p>											
										</a>
									</div> */}
									<div className="optionalResult">
										<div className='optionalResult_icon'><FontAwesomeIcon icon={faXTwitter} size="xl" /></div>
										<span>Twitter no disponible</span>
									</div>	
									
									{/* <div className="optionalResult">
										<a href={`https://www.tiktok.com/@${match.matchTiktok.toLowerCase()}`} target='_blank' rel='noopener noreferrer'>
											<FontAwesomeIcon icon={faTiktok} size="xl" /><p>{match.matchTiktok.toLowerCase()}</p>										
										</a>
									</div> */}
									<div className="optionalResult">
										<div className='optionalResult_icon'><FontAwesomeIcon icon={faTiktok} size="xl" /></div>
										<span>TikTok no disponible</span>
									</div>	
								</div>
							</div>	
						</SwiperSlide>
					)}
					
					{sortMatches?.map((match) => {
						return (
							<SwiperSlide key={match.matchUsername}>
								<div className='match'>
									<h2>{`${match.matchCompatibility}%`}</h2>
									
									<div className='results'>
										<div className="result">										
											<a href={`https://www.instagram.com/${match.matchInstagram.toLowerCase()}/`} target='_blank' rel='noopener noreferrer'>
												<FontAwesomeIcon icon={faInstagram} size="xl" />
												<p>{match.matchInstagram.toLowerCase()}</p>											
											</a>
										</div>
										
										{match.matchTwitter !== null 
											?	<div className="optionalResult">
													<a href={`https://twitter.com/${match.matchTwitter.toLowerCase()}`} target='_blank' rel='noopener noreferrer'>
														<FontAwesomeIcon icon={faXTwitter} size="xl" />
														<p>{match.matchTwitter.toLowerCase()}</p>											
													</a>
												</div>
											: <div className="optionalResult">
													<div className='optionalResult_icon'>
														<FontAwesomeIcon icon={faXTwitter} size="xl" />
													</div>
													<span>Twitter no disponible</span>
												</div>	
										}

										{match.matchTiktok !== null 
											?	<div className="optionalResult">
													<a href={`https://www.tiktok.com/@${match.matchTiktok.toLowerCase()}`} target='_blank' rel='noopener noreferrer'>
														<FontAwesomeIcon icon={faTiktok} size="xl" />
														<p>{match.matchTiktok.toLowerCase()}</p>										
													</a>
												</div>
											: <div className="optionalResult">
													<div className='optionalResult_icon'>
														<FontAwesomeIcon icon={faTiktok} size="xl" />
													</div>
													<span>TikTok no disponible</span>
												</div>	
										}
									</div>
								</div>	
							</SwiperSlide>
						)
					})}

					{sortMatches.length === 0 && (
						<SwiperSlide>
							<div className='noMatch'><p>¡¡Lo sentimos!! No hay ningún match compatible contigo</p></div>	
						</SwiperSlide>
					)}

				</Swiper>

				<div className='tableFooter'></div> 	
			</div>

			<div className='urlInstagram urlInstagramAux'>
				<a href={`https://www.instagram.com/unihell.madrid`} target='_blank' rel='noopener noreferrer'><div><div><FontAwesomeIcon className='instagramIcon' icon={faInstagram} size="xl" /></div><h3>@unihell.madrid</h3></div></a>
			</div>
		</div>
	) 
}

export default AppMatchLayout