import React from 'react';

import { Question } from "../types";

import '../App.css';


interface QuestionForTestProps {
	index: number
	question: Question
	selectedAnswer: string
	onAnswerChange: (index: number, answer: string) => void
}

const QuestionForTest = ({ index, question, selectedAnswer, onAnswerChange }: QuestionForTestProps) => {

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onAnswerChange(index, event.target.value);
  }

	return (
		<div className='question'>
			<h3> {index+1}.- {question.question} </h3>
			<div className='options'>
				{question.options.map((option, optionIndex) => (
					<label key={optionIndex}>
						<input
							type="radio"
							value={option}
							name={option}
							checked={selectedAnswer === option}
							onChange={handleChange}
						/>
						{option}
					</label>
				))}
			</div>
		</div>
	)
}

export default QuestionForTest