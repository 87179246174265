import React, { useEffect, useState, useCallback } from 'react';

import { UserSettings } from "../types";

import { useQuery, useMutation } from '@apollo/client'
import { GET_USER_SETTINGS, ALLOW_MODIFY_USER, UPDATE_USER_SETTINGS, UPDATE_USER_PASSWORD, DELETE_USER } from '../graphql/queries'

import Swal from 'sweetalert2'

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faEnvelope, faKey, faVenusMars, faHeart, faCalendar, faTimeline, faMapPin, 
				 faMagnifyingGlassLocation, faArrowRotateBack, faFloppyDisk, faTrash } from '@fortawesome/free-solid-svg-icons'
import { faInstagram, faXTwitter, faTiktok } from '@fortawesome/free-brands-svg-icons'

import { FetchError } from '../components/FetchError'

import '../App.css';


function AppUserSettingsLayout() {
  // console.log("Entrando en AppUserSettingsLayout") 

  const { data: dataUserSettings, error: errorUserSettings, loading: loadingUserSettings } = useQuery(GET_USER_SETTINGS)

	const [userSettings, setUserSettings] = useState<UserSettings>(dataUserSettings?.userSettings)
	const [newUserSettings, setNewUserSettings] = useState<UserSettings>(dataUserSettings?.userSettings)

	useEffect(() => {
		if (dataUserSettings) {
			let auxUserSettings = { ...dataUserSettings.getUserSettings } as UserSettings
			auxUserSettings.registrationDate = new Date(auxUserSettings.registrationDate)
			if (auxUserSettings.twitter === null) auxUserSettings.twitter = ''
			if (auxUserSettings.tiktok === null) auxUserSettings.tiktok = ''
			auxUserSettings.age = new Date(auxUserSettings.age)
			setUserSettings(auxUserSettings)
			setNewUserSettings(auxUserSettings)
		}
	}, [dataUserSettings])

	const [ allowModifyUser ] = useMutation(ALLOW_MODIFY_USER)
	const [ updateUserSetting ] = useMutation(UPDATE_USER_SETTINGS)
	const [ updateUserPassword ] = useMutation(UPDATE_USER_PASSWORD)
	const [ deleteUser ] = useMutation(DELETE_USER)

	const [error, setError] = useState<boolean> (false)


	const handleChangeOfInputs = (event: React.ChangeEvent<HTMLInputElement>) => {
		setNewUserSettings({...newUserSettings, [event.target.name]: event.target.value})
	}

	const handleChangeOfSelects = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setNewUserSettings({...newUserSettings, [event.target.name]: event.target.value})
	}

	const handleDateChange = (date: Date ) => {
		setNewUserSettings({...newUserSettings, age: date})
  }

	const resetUserSettings = () => {
		setNewUserSettings(userSettings)
	}

	const saveSettings = async () => {
		try {
			if (userSettings !== newUserSettings) {
				await allowModifyUser()
				
				saveUserSettings()
			}
			else {
				Swal.fire({
					icon: 'error',
					title: 'No hay cambios en los ajustes que actualizar',

					showCloseButton: window.innerWidth <= 1024,
					showConfirmButton: false,

					color: '#e6e8ec',
					background: '#000000',

					toast: true,
					position: 'top-end',
					timer: 4000,
				})
			}
		} catch (error) { 
			if ((error as Error).message === 'matchmaking in process') {
				Swal.fire({
					title: `¡Proceso de Matchmaking iniciado!`,
		
					html: `
						<div>
							<p class='sweetAlert2-myText'>Estás en mitad de un proceso de Matchmaking, mientras dure dicho proceso no puedes editar el perfil. Por favor, intentelo más tarde.</p>
						</div>
					`,
					
					showCloseButton: true,
					showConfirmButton: true,
					showCancelButton: false,
					confirmButtonText: 'Continuar',
		
					iconColor: '#e6e8ec',
					color: '#e6e8ec',
					background: '#000000',
					confirmButtonColor: '#4CAF50',
					cancelButtonColor: '#aaa'
				})
			}
			else { setError(true) }
		}
	}

	const auxSaveUserSettings = (errorTitle: string, errorText: string) => {
		Swal.fire({
			title: `¡${errorTitle}!`,

			html: `
				<div>
					<p class='sweetAlert2-myText'>${errorText}.</p>
				</div>
			`,
			
			showCloseButton: true,
			showConfirmButton: true,
			showCancelButton: false,
			confirmButtonText: 'Continuar',

			iconColor: '#e6e8ec',
			color: '#e6e8ec',
			background: '#000000',
			confirmButtonColor: '#4CAF50',
			cancelButtonColor: '#aaa'
		})
	}

	const saveUserSettings = useCallback(async () => {
		if (newUserSettings.instagram === '') auxSaveUserSettings('Instagram inválido', 'El instagram es un campo obligatorio no puede estar vacío')
		else {
			if (newUserSettings.instagram.length > 30) auxSaveUserSettings('Instagram inválido', 'Introduce un nombre de usuario válido')
			else {
				if (newUserSettings.twitter.length > 30) auxSaveUserSettings('Twitter inválido', 'Introduce un nombre de usuario válido')
				else {
					if (newUserSettings.tiktok.length > 30) auxSaveUserSettings('TikTok inválido', 'Introduce un nombre de usuario válido')
					else {
						const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
						if (!regexEmail.test(newUserSettings.email)) auxSaveUserSettings('Correo electrónico inválido', 'Introduce un nombre de correco electrónico válido')
						else {
							// const auxAge = new Date(newUserSettings.age)
							// if (isNaN(auxAge.getTime())) auxSaveUserSettings('Fecha inválida', 'Introduce una fecha de nacimiento válida')
							if (newUserSettings.age == null) auxSaveUserSettings('Fecha inválida', 'Introduce una fecha de nacimiento válida')
							else {
								const currentDate = new Date();
								if ((currentDate.getFullYear() - newUserSettings.age.getFullYear()) < 18) auxSaveUserSettings('Fecha de nacimiento inválida', 'Debes ser mayor de edad para poder usar nuestros servicios')
								else {
									if (((currentDate.getFullYear() - newUserSettings.age.getFullYear()) === 18) && 
									(currentDate.getMonth() < newUserSettings.age.getMonth() || 
									(currentDate.getMonth() === newUserSettings.age.getMonth() && currentDate.getDate() < newUserSettings.age.getDate()))) {
											auxSaveUserSettings('Fecha de nacimiento inválida', 'Debes ser mayor de edad para poder usar nuestros servicios')	
									}
									else {
										if (newUserSettings.ageRangeMin > newUserSettings.ageRangeMax) auxSaveUserSettings('Edad de tu crush inválida', 'La "edad mínima" debe ser inferior o igual a la "edad máxima"')
										else {
											try {
												const auxTwitter = newUserSettings.twitter === '' ? null : newUserSettings.twitter;
												const auxTiktok = newUserSettings.tiktok === '' ? null : newUserSettings.tiktok;
												await updateUserSetting({variables: {id: newUserSettings.id, email: newUserSettings.email.toLowerCase(), instagram: newUserSettings.instagram, twitter: auxTwitter, tiktok: auxTiktok, 
																														gender: newUserSettings.gender, sexualOrientation: newUserSettings.sexualOrientation, age: newUserSettings.age, ageRangeMin: +newUserSettings.ageRangeMin, 
																														ageRangeMax: +newUserSettings.ageRangeMax, area: newUserSettings.area, searchArea: newUserSettings.searchArea}})

												Swal.fire({
													icon: 'success',
													title: 'Ajustes de usuario actualizados correctamente',

													showCloseButton: window.innerWidth <= 1024,
													showConfirmButton: false,

													color: '#e6e8ec',
													background: '#000000',

													toast: true,
													position: 'top-end',
													timer: 4000,
												})

											} catch (error) { setError(true) }
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}, [newUserSettings, updateUserSetting])

	const updatePassword = async (currentPassword: string, newPassword: string, confirmNewPassword: string) => {
		try {
			await updateUserPassword({variables: {password: currentPassword, newPassword: newPassword}})
			
			Swal.fire({
				icon: 'success',
				title: 'Contraseña actualizada correctamente',

				showConfirmButton: false,

				color: '#e6e8ec',
				background: '#000000',

				toast: true,
				position: 'top-end',
				timer: 4000,
			})

		} catch (error) { 
			if ((error as Error).message === 'the password is not correct') {
				AuxHandleChangeOfPassword('La contraseña no es correcta', '', newPassword, confirmNewPassword) 
			}
			else { setError(true) }
		}
	}

	const handleChangeOfPassword = () => {
		AuxHandleChangeOfPassword('', '', '', '')
	}

	const AuxHandleChangeOfPassword = (error: string, currentPassword: string, newPassword: string, confirmNewPassword: string) => {
		Swal.fire({
			title: 'Actualiza tu contraseña',

			html: `
				<div>
					<p class='sweetAlert2-myText'>Introduce tu contraseña actual y una nueva contraseña.</p>

					${error === 'La contraseña no es correcta' 
						? `<div class='sweetAlert2-myError'>
								<h1>Contraseña actual</h1>
								<p>-</p>
								<p>${error}</p>
							</div>`
						: `<label class='sweetAlert2-myInputLabel'>Contraseña actual</label>`
					}
					<input type='password' value='${currentPassword}' name='currentPassword' id='currentPassword' class='sweetAlert2-myInput' />
					
					${(error === 'Tu nueva contrasena no puede estar vacía' || error === 'Debe tener 30 o menos caracteres' || error === 'Debe tener 6 o más caracteres' || 
						 error ==='Debe tener al menos una letra mayúscula' || error === 'Debe tener al menos una letra minuscula' || error === 'Debe tener al menos un número')
						? `<div class='sweetAlert2-myError'>
								<h1>Nueva contraseña</h1>
								<p>-</p>
								<p>${error}</p>
							</div>`
						: `<label class='sweetAlert2-myInputLabel'>Nueva contraseña</label>`
					}
					<input type='password' value='${newPassword}' name='newPassword' id='newPassword' class='sweetAlert2-myInput' />
					
					${error === 'La contraseña no coincide'
						? `<div class='sweetAlert2-myError'>
								<h1>Confirma nueva contraseña</h1>
								<p>-</p>
								<p>${error}</p>
							</div>`
						: `<label class='sweetAlert2-myInputLabel'>Confirma nueva contraseña</label>`
					}
					<input type='password' value='${confirmNewPassword}' name='confirmNewPassword' id='confirmNewPassword' class='sweetAlert2-myInput' />
				</div>
			`,

			preConfirm: () => {
				const AuxCurrentPassword = document.getElementById('currentPassword') as HTMLInputElement
				const currentPassword = AuxCurrentPassword.value
				const AuxNewPassword = document.getElementById('newPassword') as HTMLInputElement
				const newPassword = AuxNewPassword.value
				const AuxConfirmNewPassword = document.getElementById('confirmNewPassword') as HTMLInputElement
				const confirmNewPassword = AuxConfirmNewPassword.value
		
				return [currentPassword, newPassword, confirmNewPassword];
			},

			showCloseButton: true,
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Listo',
			cancelButtonText: 'Cancelar',
      reverseButtons: true,

			color: '#e6e8ec',
			background: '#000000',
			confirmButtonColor: '#4CAF50',
			cancelButtonColor: '#aaa',
		})
		.then((result) => {
			if (result.isConfirmed) {
				const [currentPassword, newPassword, confirmNewPassword] = result.value;

				if (newPassword === '') AuxHandleChangeOfPassword('Tu nueva contrasena no puede estar vacía', currentPassword, newPassword, confirmNewPassword)
				else {
					if (newPassword.length > 30) AuxHandleChangeOfPassword('Debe tener 30 o menos caracteres', currentPassword, newPassword, confirmNewPassword)
					else {

						// if (newPassword.length < 8) AuxHandleChangeOfPassword('Debe tener 8 o más caracteres', currentPassword, newPassword, confirmNewPassword)
						// else {
						// 	if (!/[A-Z]/.test(newPassword)) AuxHandleChangeOfPassword('Debe tener al menos una letra mayúscula', currentPassword, newPassword, confirmNewPassword)
						// 	else {
						// 		if (!/[a-z]/.test(newPassword)) AuxHandleChangeOfPassword('Debe tener al menos una letra minuscula', currentPassword, newPassword, confirmNewPassword)
						// 		else {
						// 			if (!/\d/.test(newPassword)) AuxHandleChangeOfPassword('Debe tener al menos un número', currentPassword, newPassword, confirmNewPassword)
						// 			else {
						if (newPassword.length < 6) AuxHandleChangeOfPassword('Debe tener 6 o más caracteres', currentPassword, newPassword, confirmNewPassword)
						else {
							if (newPassword !== confirmNewPassword) AuxHandleChangeOfPassword('La contraseña no coincide', currentPassword, newPassword, confirmNewPassword)
							else {
								if (currentPassword === '') AuxHandleChangeOfPassword('La contraseña no es correcta', currentPassword, newPassword, confirmNewPassword)
								else {
									updatePassword(currentPassword, newPassword, confirmNewPassword)
								}
							}
						}
					}
				}
			}
		})
	}

	const deleteUserAccount = async (password: string) => {
		try {
			await deleteUser({variables: {password: password}})
			
			Swal.fire({
				icon: 'success',
				title: 'Cuenta eliminada correctamente',

				showConfirmButton: false,

				color: '#e6e8ec',
				background: '#000000',

				toast: true,
				position: 'top-end',
				timer: 4000,
			})

			window.location.reload()

		} catch (error) { 
			if ((error as Error).message === 'the password is not correct') {
				auxDeleteAccount('La contraseña no es correcta', '') 
			}
			else { setError(true) }
		}
	}

	const deleteAccount = async () => {
		try {
			await allowModifyUser()
			
			auxDeleteAccount('', '')
		} catch (error) { 
			if ((error as Error).message === 'matchmaking in process') {
				Swal.fire({
					title: `¡Proceso de Matchmaking iniciado!`,
		
					html: `
						<div>
							<p class='sweetAlert2-myText'>Estás en mitad de un proceso de Matchmaking, mientras dure dicho proceso no puedes eliminar la cuenta. Por favor, intentelo más tarde.</p>
						</div>
					`,
					
					showCloseButton: true,
					showConfirmButton: true,
					showCancelButton: false,
					confirmButtonText: 'Continuar',
		
					iconColor: '#e6e8ec',
					color: '#e6e8ec',
					background: '#000000',
					confirmButtonColor: '#4CAF50',
					cancelButtonColor: '#aaa'
				})
			}
			else { setError(true) }
		}
	}

	const auxDeleteAccount = (error: string, password: string) => {
		Swal.fire({
			title: 'Eliminar cuenta',

			html: `
				<div>
					<p class='sweetAlert2-myText'>Introduce tu contraseña actual para verificar que eres tú.</p>

					${error === 'La contraseña no es correcta' 
						? `<div class='sweetAlert2-myError'>
								<h1>Contraseña</h1>
								<p>-</p>
								<p>${error}</p>
							</div>`
						: `<label class='sweetAlert2-myInputLabel'>Contraseña</label>`
					}
					<input type='password' value='${password}' name='deletePassword' id='deletePassword' class='sweetAlert2-myInput' />
				</div>
			`,

			preConfirm: () => {
				const AuxPassword = document.getElementById('deletePassword') as HTMLInputElement
				const password = AuxPassword.value
		
				return [password]
			},

			showCloseButton: true,
			showConfirmButton: true,
			showCancelButton: true,
			confirmButtonText: 'Listo',
			cancelButtonText: 'Cancelar',
      reverseButtons: true,

			color: '#e6e8ec',
			background: '#000000',
			confirmButtonColor: '#4CAF50',
			cancelButtonColor: '#aaa',
		})
		.then((result) => {
			if (result.isConfirmed) {
				const [password] = result.value;
					if (password === '') auxDeleteAccount('La contraseña no es correcta', password)
					else {
						deleteUserAccount(password)
					}
			}
		})
	}

	
	const ageOptions = [...Array(33)].map((_, index) => (
		<option key={index} value={index + 18}>{index === 32 ? `+${index + 18}` : index + 18}</option>
	))


	useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') { saveUserSettings() }
    }

    // Agregar el event listener cuando el componente se monta
    window.addEventListener('keydown', handleKeyPress)

    // Limpiar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    };
  }, [saveUserSettings])


	if (errorUserSettings) return <FetchError />
  if (loadingUserSettings || !userSettings || !newUserSettings) return <div className='app-loading'><h1>Cargando...</h1></div>
	
	if (error === true) return <FetchError />
	

  return (
		<div className="content">
			<div className='content-background'>
				<img src={'../img/userSettings.png'} alt="userSettings background" />
			</div>

			<div className="app-section-userSettings">

				<div className="app-section-userSettings-container">
					<div className="app-section-userSettings-container-title">
						<h1>Perfil</h1>
					</div>
					<div className="app-section-userSettings-container-contentColumn">
						<div className="app-section-userSettings-container-content-profileSetting">
							<div className='app-section-userSettings-icon'><FontAwesomeIcon icon={faUser} size="2xl"/></div>
							<div className="app-section-userSettings-container-content-profileSetting-content">
								<p>{newUserSettings.username}</p>
								<span>{`Since ${newUserSettings.registrationDate.getUTCDate().toString().padStart(2, '0')}/${(newUserSettings.registrationDate.getUTCMonth() + 1).toString().padStart(2, '0')}/${newUserSettings.registrationDate.getUTCFullYear()}`}</span>
							</div>
						</div>
						<div className="app-section-userSettings-container-content-setting-half">
							<div className='app-section-userSettings-icon'><FontAwesomeIcon icon={faInstagram} size="xl" /></div>
							<div className='app-section-userSettings-input'>
								<input type='text' value={newUserSettings.instagram} name='instagram' id='instagram' onChange={handleChangeOfInputs} />
								<label htmlFor="instagram">Instagram</label>
							</div>
						</div>
						<div className="app-section-userSettings-container-content-setting-half">
							<div className='app-section-userSettings-icon'><FontAwesomeIcon icon={faXTwitter} size="xl"/></div>
							<div className="app-section-userSettings-input">
								<input type='text' value={newUserSettings.twitter} name='twitter' id='twitter' onChange={handleChangeOfInputs} />
								<label htmlFor="twitter">Twitter</label>
							</div>
						</div>
						<div className="app-section-userSettings-container-content-setting-half">
							<div className='app-section-userSettings-icon'><FontAwesomeIcon icon={faTiktok} size="xl"/></div>
							<div className="app-section-userSettings-input">
								<input type='text' value={newUserSettings.tiktok} name='tiktok' id='tiktok' onChange={handleChangeOfInputs} />
								<label htmlFor="tiktok">Tiktok</label>
							</div>
						</div>
					</div>
				</div>

				<div className="app-section-userSettings-container">
					<div className="app-section-userSettings-container-title">
						<h1>Mi cuenta</h1>
					</div>
					<div className="app-section-userSettings-container-contentColumn">
						<div className="app-section-userSettings-container-content-setting">
							<div className='app-section-userSettings-icon'><FontAwesomeIcon icon={faEnvelope} size="xl" /></div>
							<div className="app-section-userSettings-input">
								<input type='text' value={newUserSettings.email} name='email' id='email' onChange={handleChangeOfInputs} />
								<label htmlFor="email">Correo electrónico</label>
							</div>
						</div>
						<div className="app-section-userSettings-container-content-setting">
							<div className='app-section-userSettings-icon'><FontAwesomeIcon icon={faKey} size="xl" /></div>
							<div className="app-section-userSettings-input">
								<input type='password' defaultValue={'my password input value'} name='password' id='password' onClick={handleChangeOfPassword} />
								<label htmlFor="password">Contraseña</label>
							</div>
						</div>
					</div>
				</div>

				<div className="app-section-userSettings-container">
					<div className="app-section-userSettings-container-title">
						<h1>Parámetros de búsqueda</h1>
					</div>
					<div className="app-section-userSettings-container-contentRow">
						<div className="app-section-userSettings-container-contentColumn">
							<div className="app-section-userSettings-container-content-setting">
								<div className='app-section-userSettings-icon'><FontAwesomeIcon icon={faVenusMars} size="xl" /></div>
								<div className="app-section-userSettings-input">
									<select value={newUserSettings.gender} name="gender" id="gender" onChange={handleChangeOfSelects}>
										<option value="male" >Hombre</option>
										<option value="female">Mujer</option>
									</select>
									<label htmlFor="gender">Género</label>
								</div>
							</div>
							<div className="app-section-userSettings-container-content-setting">
								<div className='app-section-userSettings-icon'><FontAwesomeIcon icon={faHeart} size="xl"/></div>
								<div className="app-section-userSettings-input">
									<select value={newUserSettings.sexualOrientation} name='sexualOrientation' id='sexualOrientation' onChange={handleChangeOfSelects}>
										<option value="heterosexual">Heterosexual</option>
										<option value="homosexual">Homosexual</option>
										<option value="bisexual">Bisexual</option>
									</select>
									<label htmlFor="sexualOrientation">Orientación sexual</label>
								</div>
							</div>
						</div>
						<div className="app-section-userSettings-container-contentColumn">
							<div className="app-section-userSettings-container-content-setting">
								<div className='app-section-userSettings-icon'><FontAwesomeIcon icon={faCalendar} size="xl"/></div>
								<div className="app-section-userSettings-input">
									{/* <input type='date' value={newUserSettings.age.toISOString().split('T')[0]} name='age' id='age' onChange={handleChangeOfInputs} readOnly /> */}
									<DatePicker
										selected={newUserSettings.age}
										onChange={handleDateChange}
										dateFormat="dd/MM/yyyy"
										showYearDropdown
										showMonthDropdown
										dropdownMode="select"
									/>
									<label htmlFor="age">Fecha de nacimiento</label>
								</div>
							</div>
							<div className="app-section-userSettings-container-content-setting">
								<div className='app-section-userSettings-icon'><FontAwesomeIcon icon={faTimeline} size="xl"/></div>
								<div className="app-section-userSettings-input-half">
									<select value={newUserSettings.ageRangeMin} name='ageRangeMin' id='ageRangeMin' onChange={handleChangeOfSelects}>
										{ageOptions}
									</select>
									<label htmlFor="ageRangeMin">Edad min de interés</label>
								</div>
								<div className="app-section-userSettings-input-half">
									<select value={newUserSettings.ageRangeMax} name='ageRangeMax' id='ageRangeMax' onChange={handleChangeOfSelects}>
										{ageOptions}
									</select>
									<label htmlFor="ageRangeMax">Edad max de interés</label>
								</div>
							</div>
						</div>
						<div className="app-section-userSettings-container-contentColumn">
							<div className="app-section-userSettings-container-content-setting">
								<div className='app-section-userSettings-icon'><FontAwesomeIcon icon={faMapPin} size="xl"/></div>
								<div className="app-section-userSettings-input">
									<select value={newUserSettings.area} name='area' id='area' onChange={handleChangeOfSelects}>
										<option value="west">Madrid Oeste</option>
										<option value="east">Madrid Este</option>
										<option value="center">Madrid Centro</option>
										<option value="north">Madrid Norte</option>
										<option value="south">Madrid Sur</option>
									</select>
									<label htmlFor="area">Zona de residencia</label>
								</div>
							</div>
							<div className="app-section-userSettings-container-content-setting">
								<div className='app-section-userSettings-icon'><FontAwesomeIcon icon={faMagnifyingGlassLocation} size="xl"/></div>
								<div className="app-section-userSettings-input">
									<select value={newUserSettings.searchArea} name='searchArea' id='searchArea' onChange={handleChangeOfSelects}>
										<option value="all">Todo Madrid</option>
										<option value="my area">Mi zona</option>
									</select>	
									<label htmlFor="searchArea">Zona de interés</label>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="app-section-userSettings-container">
					<div className="app-section-userSettings-container-title">
						<h1>Eliminar cuenta</h1>
					</div>
					<div className="app-section-userSettings-container-contentColumn">
						<div className="app-section-userSettings-container-content-deleteAccount">
							<p>Una vez que elimines tu cuenta no hay vuelta atrás, por favor, asegurate de que quieres eliminarla</p>
							<div className='app-section-userSettings-button' onClick={deleteAccount}>
								<div className='app-section-userSettings-icon'><FontAwesomeIcon icon={faTrash} size="xl"/></div>
								<h1>Eliminar Cuenta</h1>
							</div>
						</div>
					</div>
				</div>

				<div className='app-section-userSettings-buttons'>
					<div className='app-section-userSettings-button' onClick={resetUserSettings}>
						<div className='app-section-userSettings-icon'><FontAwesomeIcon icon={faArrowRotateBack} size="xl"/></div>
						<h1>Restablecer</h1>
					</div>
					<div className='app-section-userSettings-button' onClick={saveSettings}>
						<div className='app-section-userSettings-icon'><FontAwesomeIcon icon={faFloppyDisk} size="xl"/></div>
						<h1>Guardar</h1>
					</div>
				</div>

			</div>
		</div>
  )
}

export default AppUserSettingsLayout
