import React from 'react';

import { useNavigate , Link } from "react-router-dom"

import { Test, AnswersToTest } from "../types"

import { useQuery } from '@apollo/client'
import { GET_COMPATIBILITY_TESTS, GET_ANSWERS_BY_USER } from '../graphql/queries'

import Swal from 'sweetalert2'

import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

import { FetchError } from '../components/FetchError'

import * as routesPaths from '../consts/routesPaths'

import '../App.css'



function AppCompatibilityTestsLayout() {
  // console.log("Entrando en AppCompatibilityTestsLayout")

  const navigate = useNavigate()

  const { data: dataTests, error: errorTests, loading: loadingTests } = useQuery(GET_COMPATIBILITY_TESTS)
  const tests: Test[] = dataTests?.getCompatibilityTestsForUser as Test[]

  const { data: dataAnswersToTests, error: errorAnswersToTests, loading: loadingAnswersToTests } = useQuery(GET_ANSWERS_BY_USER)
  const answersToTests: AnswersToTest[] = dataAnswersToTests?.getAnswersToTestsByUser as AnswersToTest[]

  if (loadingTests || loadingAnswersToTests) return <div className='app-loading'><h1>Cargando...</h1></div>
  if (errorTests) return <FetchError />
  if (errorAnswersToTests) return <FetchError />


  const handleClick = (test: Test, answersToTest: AnswersToTest) => {
    return () => {
      Swal.fire({
        // icon: 'info',
        title: `¿Quiere modificar las respuestas enviadas al "${test.name}"?`,

        html: `
          <div>
            <p class='sweetAlert2-myText'>Haga click en el botón "Modificar Test" para continuar.</p>
          </div>
        `,
        
        showCloseButton: true,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Modificar Test',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,

        iconColor: '#e6e8ec',
        color: '#e6e8ec',
        background: '#000000',
        confirmButtonColor: '#4CAF50',
        cancelButtonColor: '#aaa'
      }).then((result) => {
        if (result.isConfirmed) {
          const encodedTest = encodeURIComponent(JSON.stringify(test))
          const encodedAnswers = encodeURIComponent(JSON.stringify(answersToTest))
          navigate(`${routesPaths.APP_COMPATIBILITY_TEST}?test=${encodedTest}&answersToTest=${encodedAnswers}`)
        }
      })
    }
  }

  const slidesPerView = window.innerWidth <= 1024 ? 1 : 1.5
  const flyer = window.innerWidth <= 1024 ? 'mobil' : 'pc'

  
  return (
    <div className='content'>
      <div className='content-header'>
        <h1>Tests de Compatibilidad</h1>
        {/* <p>
          Si quieres encontrar a tu media naranja, esa persona totalmente
          compatible contigo... ¡¡No lo dudes!! Haz uno de nuestros test de 
          compatibilidad y te aseguramos que vas a encontrarla.
        </p> */}
        {/* <p>
          ¡Realiza nuestro test de compatibilidad! Gracias a nuestro algoritmo 
          únicamente necesitamos 15 preguntas para saber si una persona es 
          perfecta para ti. Responde sinceramente y nosostros hacemos el resto ;)
        </p> */}
        {/* <p>
          Completa las 35 preguntas y dale a guardar , gracias al algoritmo de unihell 
          nos es suficiente para encontrarte al menos 15 Matches compatibles.
          El test se cierra el día 12 a las 12:00 PM. Una vez completado el test podéis editarlo 
          sin problemas dándole de nuevo al test de compatibilidad. Si quieres cambiar los parámetros 
          de búsqueda puestos en el registro también puedes hacerlo desde la pestaña de ajustes.
        </p> */}
        <p>
          Solo necesitamos 35 preguntas para encontrarte personas compatibles. Sincerate y déjanos el resto a nosotros 😉.
          Una vez completado el test podéis editarlo sin problemas dándole de nuevo al test de compatibilidad.
        </p>
      </div>

      {tests.length === 0
        ? <div className='noSwiper'>
            <h1>¡¡No hay tests de compatibilidad disponibles!!</h1>
            {/* <h2>Proximo test: 09/04</h2>
            <h2>Edición Primavera UAH</h2> */}
            <div className='noSwiper-content'>
              <p>¡Síguenos en nuestra cuenta de Instagram para enterarte de todo lo que sucede!</p>
              <a href={`https://www.instagram.com/unihell.madrid`} target='_blank' rel='noopener noreferrer'><h3>@unihell.madrid</h3></a>
            </div>
          </div>
        : <Swiper id='swiperCompabilityTests'
            slidesPerView={slidesPerView}
            centeredSlides={true}
            spaceBetween={30}
            // lazyLoading={true}
            loop={false}
            
            pagination={{clickable: true}}
            navigation={true}

            autoplay={{delay: 5000, disableOnInteraction: false, pauseOnMouseEnter: true}}
              
            modules={[Pagination, Navigation, Autoplay]}
          >
            {tests?.map((test) => {
              const answersToTest = answersToTests.find(answersToTest => answersToTest.idTest === test.id)
              const closingDate = new Date(test.closingDate)

              return (
                <SwiperSlide key={test.id}>
                  {answersToTest
                    ? <div className="swiper-slide-overlay" onClick={handleClick(test, answersToTest)}>
                        <div className="swiper-slide-overlay-color"></div>
                        <div className='swiper-slide-overlay-content'>
                          <h3>Test</h3>
                          <h3>Completado</h3>
                        </div>
                        
                        <img src={`../../img/${test?.background}_${flyer}.png`} alt="slide test background" />
                        <div className='swiper-slide-content'>
                          <h3>{test.name}</h3>
                          <p>{`Disponible hasta el ${closingDate.getUTCDate().toString().padStart(2, '0')}/${(closingDate.getUTCMonth() + 1).toString().padStart(2, '0')}/${closingDate.getUTCFullYear()} 
                              hasta las ${closingDate.getUTCHours().toString().padStart(2, '0')}:${closingDate.getUTCMinutes().toString().padStart(2, '0')}:${closingDate.getUTCSeconds().toString().padStart(2, '0')}`}</p>
                        </div>
                        
                      </div>
                    : <Link to={`${routesPaths.APP_COMPATIBILITY_TEST}?test=${encodeURIComponent(JSON.stringify(test))}`}>
                        <img src={`../../img/${test?.background}_${flyer}.png`} alt="slide test background" />
                        <div className='swiper-slide-content'>
                          <h3>{test.name}</h3>
                          <p>{`Disponible hasta el ${closingDate.getUTCDate().toString().padStart(2, '0')}/${(closingDate.getUTCMonth() + 1).toString().padStart(2, '0')}/${closingDate.getUTCFullYear()} 
                              hasta las ${closingDate.getUTCHours().toString().padStart(2, '0')}:${closingDate.getUTCMinutes().toString().padStart(2, '0')}:${closingDate.getUTCSeconds().toString().padStart(2, '0')}`}</p>
                        </div>
                      </Link>
                  }
                </SwiperSlide>
              )
            })}
          </Swiper>
      }

      <div className='urlInstagram'>
        <a href={`https://www.instagram.com/unihell.madrid`} target='_blank' rel='noopener noreferrer'><div><div><FontAwesomeIcon className='instagramIcon' icon={faInstagram} size="xl" /></div><h3>@unihell.madrid</h3></div></a>
      </div>

    </div>
  )
}

export default AppCompatibilityTestsLayout
