import React, { useEffect, useState, useCallback } from 'react'

import { useNavigate } from 'react-router-dom';

import { SignIn, SignUp, User } from "../types"

import { useMutation, useQuery } from '@apollo/client'
import { SINGIN, SINGUP, USER, EMAIL_TO_RESET_PASSWORD } from '../graphql/queries'

import Swal from 'sweetalert2'

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import * as routesPaths from '../consts/routesPaths';

import { FetchErrorNavbar } from '../components/FetchError'

import '../App.css'


const NO_VALID_EMAIL = 'introduce una dirección de correo electrónico válida'
const NO_VALID_PASSWORD = 'la contraseña debe tener 6 o más caracteres'
const NO_CONFIRM_PASSWORD = 'la contraseña no coincide'
const NO_USERNAME = 'el campo "Nombre de usuario" no puede estar vacío'
const NO_INSTAGRAM = 'el campo "Cuenta de Instagram" no puede estar vacío'
const NO_GENDER = 'seleccione una opción para el campo "Género"'
const NO_SEXUAL_ORIENTATION = 'seleccione una opción para el campo "Orientación sexual"'
const NO_DATE = 'fecha inválida'
const NO_AGE = 'debes ser mayor de edad'
const NO_AREA = 'seleccione una opción para el campo "Zona de residencia"'
const NO_SEARCH_AREA  = 'seleccione una opción para el campo "Zona de interés"'

const REGEX_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const UPPERCASE_LETTER = /[A-Z]/   
const LOWERCASE_LETTER = /[a-z]/
const NUMBER = /\d/


// const privacyPolicyURL = `https://${process.env.REACT_APP_PRIVACY_POLICY_URL}/privacyPolicy`
const privacyPolicyURL = `https://unihell.com/privacyPolicy`


interface SignInUpFormState {
	signInCredentials: SignIn
	signUpCredentials: SignUp
}

// Seguramente tenga que hacer el LoginFormProps para cambiar el estado del usuario

const SignInUpLayout = () => {
	// console.log("Entrando en SignInUpLayout")

	const navigate = useNavigate()

	const [isSignUpActive, setSignUpActive] = useState(false)

	// Mirar si entra algún tipo a la función
  const handleSignUpClick = () => setSignUpActive(true)
  const handleSignInClick = () => setSignUpActive(false)


	// Mirar si hago un estado más complejo con el useReducer
	const [signInCredentials, setSignInCredentials] = useState<SignInUpFormState["signInCredentials"]>({email: '', password: ''})
	const [signUpCredentials, setSignUpCredentials] = useState<SignInUpFormState["signUpCredentials"]>({username: '', email: '', password: '', confirmPassword: '', instagram: '', twitter: '', tiktok: '', gender: '', sexualOrientation: '', age: new Date(), ageRangeMin: 18, ageRangeMax: 50, area: '', searchArea: ''})

	const [privacyPolicy, setPrivacyPolicy] = useState(false)
	const handlePrivacyPolicy = () => setPrivacyPolicy(prevPrivacyPolicy => !prevPrivacyPolicy)

	const [signUpStatus, setSignUpStatus] = useState<number>(1)
	const [signUpErrors, setSignUpErrors] = useState<string[]>([''])


	const [ signIn, resultSignIn ] = useMutation(SINGIN)
	const [ signUp, resultSignUp ] = useMutation(SINGUP)
	const [ emailToResetPassword ] = useMutation(EMAIL_TO_RESET_PASSWORD)

	const [error, setError] = useState<boolean> (false)

	useEffect(() => {
		if (resultSignIn.data) {
			setSignInCredentials({email: '', password: ''})
			const { value: token } = resultSignIn.data.signIn
			localStorage.setItem('auth-token', token)
			resultSignIn.client.resetStore()
			navigate("/")
		}
	}, [resultSignIn.data])

	useEffect(() => {
		if (resultSignUp.data) {
			setSignUpCredentials({username: '', email: '', password: '', confirmPassword: '', instagram: '', twitter: '', tiktok: '', gender: '', sexualOrientation: '', age: new Date(), ageRangeMin: 18, ageRangeMax: 50, area: '', searchArea: ''})
			const { value: token } = resultSignUp.data.signUp
			localStorage.setItem('auth-token', token)
			resultSignUp.client.resetStore()
			navigate("/")
		}
	}, [resultSignUp.data])


	const swalRecoverUserPassword = (title: String, content: String) => {
		Swal.fire({
			title: title,
			html: `<div><p class='sweetAlert2-myText'>${content}</p></div>`,
			
			showCloseButton: true,
			showConfirmButton: true,
			showCancelButton: false,
			confirmButtonText: 'Continuar',

			iconColor: '#e6e8ec',
			color: '#e6e8ec',
			background: '#000000',
			confirmButtonColor: '#4CAF50',
			cancelButtonColor: '#aaa'
		})
	}

	const recoverUserPassword = async () => {
		if (!signInCredentials.email) swalRecoverUserPassword('¡Dirección de correo electrónico inválida!', 'Por favor, introduce una dirección de correo electrónico válida.')
		else {
			try {
				await emailToResetPassword({variables: {email: signInCredentials.email.toLowerCase()}})
				swalRecoverUserPassword('Instrucciones enviadas', `Hemos enviado instrucciones para cambiar tu contraseña a ${signInCredentials.email.toLowerCase()}. Revisa la carpeta de entrada y la carpeta de spam.`)
			} catch (error) {
				if ((error as Error).message === 'invalid email') swalRecoverUserPassword('¡Dirección de correo electrónico inválida!', 'Por favor, introduce una dirección de correo electrónico válida.')
				else setError(true)
			}
		}	
	}


	const handleSubmitSignIn = useCallback(async () => {
		try {
			await signIn({variables: {email: signInCredentials.email.toLowerCase(), password: signInCredentials.password}})
		} catch (error) {
			if ((error as Error).message === 'wrong credentials') {
				setSignInCredentials({email: signInCredentials.email, password: ''})

				Swal.fire({
					title: `¡Credenciales incorrectas!`,
		
					html: `
						<div>
							<p class='sweetAlert2-myText'>Asegúrate de introducir una dirección de correo electrónico válida y la contraseña asociada.</p>
						</div>
					`,
					
					showCloseButton: true,
					showConfirmButton: true,
					showCancelButton: false,
					confirmButtonText: 'Continuar',
		
					iconColor: '#e6e8ec',
					color: '#e6e8ec',
					background: '#000000',
					confirmButtonColor: '#4CAF50',
					cancelButtonColor: '#aaa'
				})
			} else { setError(true) }
		}
	}, [signIn, signInCredentials])

	const handleChangeSignIn = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSignInCredentials({...signInCredentials, [event.target.name]: event.target.value})
	}


	const handleSubmitSignUp = useCallback(async () => {
		try {
			const auxTwitter = signUpCredentials.twitter === '' ? null : signUpCredentials.twitter
			const auxTiktok = signUpCredentials.tiktok === '' ? null : signUpCredentials.tiktok
			await signUp({variables: {email: signUpCredentials.email.toLowerCase(), password: signUpCredentials.password, username: signUpCredentials.username, instagram: signUpCredentials.instagram, 
															  twitter: auxTwitter, tiktok: auxTiktok, gender: signUpCredentials.gender, sexualOrientation: signUpCredentials.sexualOrientation, age: signUpCredentials.age, 
																ageRangeMin: +signUpCredentials.ageRangeMin, ageRangeMax: +signUpCredentials.ageRangeMax, area: signUpCredentials.area, searchArea: signUpCredentials.searchArea}})
		} catch (error) {
			if ((error as Error).message === 'duplicate username') {
				setSignUpCredentials({ ...signUpCredentials, username: '' })
				setSignUpStatus(3)

				Swal.fire({
					title: `¡Nombre de usuario en uso!`,
		
					html: `
						<div>
							<p class='sweetAlert2-myText'>Por favor, introduce un nuevo nombre de usuario.</p>
						</div>
					`,
					
					showCloseButton: true,
					showConfirmButton: true,
					showCancelButton: false,
					confirmButtonText: 'Continuar',
		
					iconColor: '#e6e8ec',
					color: '#e6e8ec',
					background: '#000000',
					confirmButtonColor: '#4CAF50',
					cancelButtonColor: '#aaa'
				})
			}
			else if ((error as Error).message === 'duplicate email') {
				setSignUpCredentials({ ...signUpCredentials, email: '' })
				setSignUpStatus(1)

				Swal.fire({
					title: `¡Dirección de correo electrónico inválido o en uso!`,
		
					html: `
						<div>
							<p class='sweetAlert2-myText'>Por favor, introduce una nueva dirección de correo electrónico.</p>
						</div>
					`,
					
					showCloseButton: true,
					showConfirmButton: true,
					showCancelButton: false,
					confirmButtonText: 'Continuar',
		
					iconColor: '#e6e8ec',
					color: '#e6e8ec',
					background: '#000000',
					confirmButtonColor: '#4CAF50',
					cancelButtonColor: '#aaa'
				})
			} else { setError(true) }
		}
	}, [signUp, signUpCredentials])
	
	const nextPageSignUp = useCallback(async () => {
		switch (signUpStatus) {
			case 1:
				const passTest = REGEX_EMAIL.test(signUpCredentials.email)
				if (!passTest && !signUpErrors.includes(NO_VALID_EMAIL)) setSignUpErrors(prevSignUpError => [...prevSignUpError, NO_VALID_EMAIL])
				if (passTest && signUpErrors.includes(NO_VALID_EMAIL)) setSignUpErrors(prevSignUpError => prevSignUpError.filter(error => error !== NO_VALID_EMAIL))
				
				if (passTest) setSignUpStatus(signUpStatus + 1)
				break
			case 2:
				// const NO_VALID_PASSWORD = 'la contraseña debe tener 8 o más caracteres'
				// const NO_UPPERCASE_LETTER = 'la contraseña debe tener al menos una letra mayuscula'
				// const NO_LOWERCASE_LETTER = 'la contraseña debe tener al menos una letra minuscula'
				// const NO_NUMBER = 'la contraseña debe tener al menos un número'

				// if (signUpCredentials.password.length < 8 && !signUpErrors.includes(NO_VALID_PASSWORD)) setSignUpErrors(prevSignUpError => [...prevSignUpError, NO_VALID_PASSWORD])
				// if (signUpCredentials.password.length >= 8 && signUpErrors.includes(NO_VALID_PASSWORD)) setSignUpErrors(prevSignUpError => prevSignUpError.filter(error => error !== NO_VALID_PASSWORD))
				
				// const passUppercaseLetterTest = UPPERCASE_LETTER.test(signUpCredentials.password)
				// if (!passUppercaseLetterTest && !signUpErrors.includes(NO_UPPERCASE_LETTER)) setSignUpErrors(prevSignUpError => [...prevSignUpError, NO_UPPERCASE_LETTER])
				// if (passUppercaseLetterTest && signUpErrors.includes(NO_UPPERCASE_LETTER)) setSignUpErrors(prevSignUpError => prevSignUpError.filter(error => error !== NO_UPPERCASE_LETTER))

				// const passLowercaseLetterTest = LOWERCASE_LETTER.test(signUpCredentials.password)
				// if (!passLowercaseLetterTest && !signUpErrors.includes(NO_LOWERCASE_LETTER)) setSignUpErrors(prevSignUpError => [...prevSignUpError, NO_LOWERCASE_LETTER])
				// if (passLowercaseLetterTest && signUpErrors.includes(NO_LOWERCASE_LETTER)) setSignUpErrors(prevSignUpError => prevSignUpError.filter(error => error !== NO_LOWERCASE_LETTER))

				// const passNumberTest = NUMBER.test(signUpCredentials.password)
				// if (!passNumberTest && !signUpErrors.includes(NO_NUMBER)) setSignUpErrors(prevSignUpError => [...prevSignUpError, NO_NUMBER])
				// if (passNumberTest && signUpErrors.includes(NO_NUMBER)) setSignUpErrors(prevSignUpError => prevSignUpError.filter(error => error !== NO_NUMBER))

				// if (signUpCredentials.password.length >= 8 && passUppercaseLetterTest && passLowercaseLetterTest && passNumberTest) {
				// 	if (signUpCredentials.password !== signUpCredentials.confirmPassword && !signUpErrors.includes(NO_CONFIRM_PASSWORD)) setSignUpErrors(prevSignUpError => [...prevSignUpError, NO_CONFIRM_PASSWORD])
				// 	if (signUpCredentials.password === signUpCredentials.confirmPassword && signUpErrors.includes(NO_CONFIRM_PASSWORD)) setSignUpErrors(prevSignUpError => prevSignUpError.filter(error => error !== NO_CONFIRM_PASSWORD))
				
				// 	if (signUpCredentials.password === signUpCredentials.confirmPassword) setSignUpStatus(signUpStatus + 1)
				// }

				if (signUpCredentials.password.length < 6 && !signUpErrors.includes(NO_VALID_PASSWORD)) setSignUpErrors(prevSignUpError => [...prevSignUpError, NO_VALID_PASSWORD])
				if (signUpCredentials.password.length >= 6 && signUpErrors.includes(NO_VALID_PASSWORD)) setSignUpErrors(prevSignUpError => prevSignUpError.filter(error => error !== NO_VALID_PASSWORD))
				
				if (signUpCredentials.password.length >= 6) {
					if (signUpCredentials.password !== signUpCredentials.confirmPassword && !signUpErrors.includes(NO_CONFIRM_PASSWORD)) setSignUpErrors(prevSignUpError => [...prevSignUpError, NO_CONFIRM_PASSWORD])
					if (signUpCredentials.password === signUpCredentials.confirmPassword && signUpErrors.includes(NO_CONFIRM_PASSWORD)) setSignUpErrors(prevSignUpError => prevSignUpError.filter(error => error !== NO_CONFIRM_PASSWORD))
					if (signUpCredentials.password === signUpCredentials.confirmPassword) setSignUpStatus(signUpStatus + 1)
				}
				break
			case 3:
				if (signUpCredentials.username === '' && !signUpErrors.includes(NO_USERNAME)) setSignUpErrors(prevSignUpError => [...prevSignUpError, NO_USERNAME])
				if (signUpCredentials.username !== '' && signUpErrors.includes(NO_USERNAME)) setSignUpErrors(prevSignUpError => prevSignUpError.filter(error => error !== NO_USERNAME))

				if (signUpCredentials.instagram === '' && !signUpErrors.includes(NO_INSTAGRAM)) setSignUpErrors(prevSignUpError => [...prevSignUpError, NO_INSTAGRAM])
				if (signUpCredentials.instagram !== '' && signUpErrors.includes(NO_INSTAGRAM)) setSignUpErrors(prevSignUpError => prevSignUpError.filter(error => error !== NO_INSTAGRAM))

				if (signUpCredentials.username !== '' && signUpCredentials.instagram !== '') setSignUpStatus(signUpStatus + 1)
				break
			// case 4:
			// 	setSignUpStatus(signUpStatus + 1)
			// 	break
			case 4:
				if (signUpCredentials.gender === '' && !signUpErrors.includes(NO_GENDER)) setSignUpErrors(prevSignUpError => [...prevSignUpError, NO_GENDER])
				if (signUpCredentials.gender !== '' && signUpErrors.includes(NO_GENDER)) setSignUpErrors(prevSignUpError => prevSignUpError.filter(error => error !== NO_GENDER))

				if (signUpCredentials.sexualOrientation === '' && !signUpErrors.includes(NO_SEXUAL_ORIENTATION)) setSignUpErrors(prevSignUpError => [...prevSignUpError, NO_SEXUAL_ORIENTATION])
				if (signUpCredentials.sexualOrientation !== '' && signUpErrors.includes(NO_SEXUAL_ORIENTATION)) setSignUpErrors(prevSignUpError => prevSignUpError.filter(error => error !== NO_SEXUAL_ORIENTATION))

				if (signUpCredentials.gender !== '' && signUpCredentials.sexualOrientation !== '') setSignUpStatus(signUpStatus + 1)
				break
			case 5:
				if (signUpCredentials.age == null && !signUpErrors.includes(NO_DATE)) setSignUpErrors(prevSignUpError => [...prevSignUpError, NO_DATE])
				if (signUpCredentials.age != null && signUpErrors.includes(NO_DATE)) setSignUpErrors(prevSignUpError => prevSignUpError.filter(error => error !== NO_DATE))

				if (signUpCredentials.age != null) {
					const currentDate = new Date()
					if ((currentDate.getFullYear() - signUpCredentials.age.getFullYear()) < 18 && !signUpErrors.includes(NO_AGE)) setSignUpErrors(prevSignUpError => [...prevSignUpError, NO_AGE])
					if ((currentDate.getFullYear() - signUpCredentials.age.getFullYear()) > 18 && signUpErrors.includes(NO_AGE)) setSignUpErrors(prevSignUpError => prevSignUpError.filter(error => error !== NO_AGE))
					if ((currentDate.getFullYear() - signUpCredentials.age.getFullYear()) > 18) setSignUpStatus(signUpStatus + 1)

					if ((currentDate.getFullYear() - signUpCredentials.age.getFullYear()) === 18) {
						if (currentDate.getMonth() < signUpCredentials.age.getMonth() && !signUpErrors.includes(NO_AGE)) setSignUpErrors(prevSignUpError => [...prevSignUpError, NO_AGE])
						if (currentDate.getMonth() > signUpCredentials.age.getMonth() && signUpErrors.includes(NO_AGE)) setSignUpErrors(prevSignUpError => prevSignUpError.filter(error => error !== NO_AGE))
						if (currentDate.getMonth() > signUpCredentials.age.getMonth()) setSignUpStatus(signUpStatus + 1)

						if (currentDate.getMonth() === signUpCredentials.age.getMonth()) {
							if (currentDate.getDate() < signUpCredentials.age.getDate() && !signUpErrors.includes(NO_AGE)) setSignUpErrors(prevSignUpError => [...prevSignUpError, NO_AGE])
							if (currentDate.getDate() >= signUpCredentials.age.getDate() && signUpErrors.includes(NO_AGE)) setSignUpErrors(prevSignUpError => prevSignUpError.filter(error => error !== NO_AGE))
							if (currentDate.getDate() >= signUpCredentials.age.getDate()) setSignUpStatus(signUpStatus + 1)
						}
					}
				}
				break
			case 6:
				if (signUpCredentials.area === '' && !signUpErrors.includes(NO_AREA)) setSignUpErrors(prevSignUpError => [...prevSignUpError, NO_AREA])
				if (signUpCredentials.area !== '' && signUpErrors.includes(NO_AREA)) setSignUpErrors(prevSignUpError => prevSignUpError.filter(error => error !== NO_AREA))

				if (signUpCredentials.searchArea === '' && !signUpErrors.includes(NO_SEARCH_AREA)) setSignUpErrors(prevSignUpError => [...prevSignUpError, NO_SEARCH_AREA])
				if (signUpCredentials.searchArea !== '' && signUpErrors.includes(NO_SEARCH_AREA)) setSignUpErrors(prevSignUpError => prevSignUpError.filter(error => error !== NO_SEARCH_AREA))

				if (signUpCredentials.area !== '' && signUpCredentials.searchArea !== '') setSignUpStatus(signUpStatus + 1)
				break
			case 7:
				if (privacyPolicy === false) {
					Swal.fire({
						title: `¡Política de Privacidad no aceptada!`,
			
						html: `
							<div>
								<p class='sweetAlert2-myText'>Por favor, lea y acepte nuestras Políticas de Privacidad de Datos.</p>
							</div>
						`,
						
						showCloseButton: true,
						showConfirmButton: true,
						showCancelButton: false,
						confirmButtonText: 'Continuar',
			
						iconColor: '#e6e8ec',
						color: '#e6e8ec',
						background: '#000000',
						confirmButtonColor: '#4CAF50',
						cancelButtonColor: '#aaa'
					})
				}
				else {
					await handleSubmitSignUp()
				}
				break
		}
	}, [handleSubmitSignUp, signUpCredentials, privacyPolicy, signUpErrors, signUpStatus])

	const previousPageSignUp = () => {
		setSignUpStatus(signUpStatus - 1)
	}

	const handleChangeOfInputsSignUp = (event: React.ChangeEvent<HTMLInputElement>) => {
		switch (event.target.name) {
			case 'password':
				if (event.target.value.length <= 30) setSignUpCredentials({...signUpCredentials, [event.target.name]: event.target.value})
				break
			case 'username':
				if (event.target.value.length <= 15) setSignUpCredentials({...signUpCredentials, [event.target.name]: event.target.value.toLowerCase()})
				break
			case 'instagram':
				if (event.target.value.length <= 30) setSignUpCredentials({...signUpCredentials, [event.target.name]: event.target.value})
				break
			case 'twitter':
				if (event.target.value.length <= 30) setSignUpCredentials({...signUpCredentials, [event.target.name]: event.target.value})
				break
			case 'tiktok':
				if (event.target.value.length <= 30) setSignUpCredentials({...signUpCredentials, [event.target.name]: event.target.value})
				break
			default:
				setSignUpCredentials({...signUpCredentials, [event.target.name]: event.target.value})
		}
	}

	const handleDateChange = (date: Date ) => {
		setSignUpCredentials({...signUpCredentials, age: date})
  }

	const handleChangeOfSelectsSignUp = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSignUpCredentials({...signUpCredentials, [event.target.name]: event.target.value})
	}


	useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
				if (!isSignUpActive) { handleSubmitSignIn()	}
				else { nextPageSignUp() }
			}
    }

    // Agregar el event listener cuando el componente se monta
    window.addEventListener('keydown', handleKeyPress)

    // Limpiar el event listener cuando el componente se desmonta
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    };
  }, [isSignUpActive, signInCredentials, signUpCredentials, handleSubmitSignIn, nextPageSignUp])
	

	const ageOptions = (key: string, start: number, end: number) => {
		return [...Array((end - start) + 1)].map((_, index) => (
			<option key={`${key}${index}`} value={index + start}>{index === (end - start) ? `+${index + start}` : index + start}</option>
		))
	}


	const { data: dataMe, error: errorMe, loading: loadingMe } = useQuery(USER)
  const me: User = dataMe?.user as User

  if (loadingMe) return <div className='app-loading-navbar'><h1>Cargando...</h1></div>
  if (errorMe) return <FetchErrorNavbar />

	if (error === true) return <FetchErrorNavbar />

  if (me) {
    navigate(routesPaths.APP)
    return null
  }


  return (
		<div className={`signInUpContainer ${isSignUpActive ? 'right-panel-active' : ''}`} id="container">

			<div className="form-container sign-up-container">
				<div className='signInUpPage signUpPage'>
					<div className="signInUpLogo">
						<img src="../img/logo.png" alt="Secondary App logo" />
					</div>
					{(() => {
						switch (signUpStatus) {
							case 1:
								return (
									<div className='signInUpContent'>
										<h2>Cómo iniciarás sesión</h2>
										<span>Introduce una dirección de correo electrónico válida con la que iniciar sesión en UniHell</span>
										<span></span><span></span>
										<div className='signInUpInputs'>
											<div className='signInUpInput'>
												<input type="email" value={signUpCredentials.email} name="email" id="email" placeholder="" onChange={handleChangeOfInputsSignUp} required />
												<label htmlFor="email" className="signInUpLabel">Correo electrónico</label>
												<label htmlFor="email" className={`${signUpErrors.includes(NO_VALID_EMAIL) ? 'signInUpLabelError' : 'signInUpLabelNotError'}`}>{`Error: ${NO_VALID_EMAIL}`}</label>
											</div>
										</div>
										<h3>Pasos completados: 1 de 6</h3>
									</div>
								)
							case 2:
								return (
									<div className='signInUpContent'>
										<h2>Crea una clave segura</h2>
										<span>Crea una contraseña segura y que recuerdes mediante una combinación de letras, números y simbolos</span>
										<span></span><span></span>
										<div className='signInUpInputs'>
											<div className="signInUpInput">
												<input type="password" value={signUpCredentials.password} name="password" id="password" placeholder="" onChange={handleChangeOfInputsSignUp} required />
												<label htmlFor="password" className="signInUpLabel">Contraseña</label>
												<label htmlFor="password" className={`${signUpErrors.includes(NO_VALID_PASSWORD) ? 'signInUpLabelError' : 'signInUpLabelNotError'}`}>{`Error: ${NO_VALID_PASSWORD}`}</label>
												{/* <label htmlFor="password" className={`${signUpErrors.includes(NO_UPPERCASE_LETTER) && !signUpErrors.includes(NO_VALID_PASSWORD) ? 'signInUpLabelError' : 'signInUpLabelNotError'}`}>{`Error: ${NO_UPPERCASE_LETTER}`}</label>
												<label htmlFor="password" className={`${signUpErrors.includes(NO_LOWERCASE_LETTER) && !signUpErrors.includes(NO_VALID_PASSWORD) && !signUpErrors.includes(NO_UPPERCASE_LETTER) ? 'signInUpLabelError' : 'signInUpLabelNotError'}`}>{`Error: ${NO_LOWERCASE_LETTER}`}</label>
												<label htmlFor="password" className={`${signUpErrors.includes(NO_NUMBER) && !signUpErrors.includes(NO_VALID_PASSWORD) && !signUpErrors.includes(NO_UPPERCASE_LETTER) && !signUpErrors.includes(NO_LOWERCASE_LETTER) ? 'signInUpLabelError' : 'signInUpLabelNotError'}`}>{`Error: ${NO_NUMBER}`}</label> */}
											</div>
											<div className="signInUpInput">
												<input type="password" value={signUpCredentials.confirmPassword} name="confirmPassword" id="confirmPassword" placeholder="" onChange={handleChangeOfInputsSignUp} required />
												<label htmlFor="confirmPassword" className="signInUpLabel">Confirma la contraseña</label>
												<label htmlFor="confirmPassword" className={`${signUpErrors.includes(NO_CONFIRM_PASSWORD) ? 'signInUpLabelError' : 'signInUpLabelNotError'}`}>{`Error: ${NO_CONFIRM_PASSWORD}`}</label>
											</div>
										</div>
										<h3>Pasos completados: 2 de 6</h3>
									</div>
								)
							case 3:
								return (
									<div className='signInUpContent'>
										<h2>Información básica</h2>
										<span>Introduce un nombre de usuario y tu Instagram para que tus matches puedan encontrarte</span>
										<span></span><span></span>
										<div className='signInUpInputs'>
											<div className="signInUpInput">
												<input type="text" value={signUpCredentials.username} name="username" id="username" placeholder="" onChange={handleChangeOfInputsSignUp} required />
												<label htmlFor="username" className="signInUpLabel">Nombre de usuario</label>
												<label htmlFor="username" className={`${signUpErrors.includes(NO_USERNAME) ? 'signInUpLabelError' : 'signInUpLabelNotError'}`}>{`Error: ${NO_USERNAME}`}</label>
											</div>
											<div className="signInUpInput">
												<input type="text" value={signUpCredentials.instagram} name="instagram" id="instagram" placeholder="" onChange={handleChangeOfInputsSignUp} required />
												<label htmlFor="instagram" className="signInUpLabel">Cuenta de Instagram (usuario sin @)</label>
												<label htmlFor="instagram" className={`${signUpErrors.includes(NO_INSTAGRAM) ? 'signInUpLabelError' : 'signInUpLabelNotError'}`}>{`Error: ${NO_INSTAGRAM}`}</label>
											</div>
										</div>
										<h3>Pasos completados: 3 de 6</h3>
									</div>
								)
							// case 4: 
							// 		return (				
							// 			<div className='signInUpContent'>
							// 				<h2>Otras redes sociales</h2>
							// 				<span>Aporta alguna de las siguientes redes sociales para que tus matches te conozcan más</span>
							// 				<span></span><span></span>
							// 				<div className='signInUpInputs'>
							// 					<div className="signInUpInput">
							// 						<input type="text" value={signUpCredentials.twitter} name="twitter" id="twitter" placeholder="" onChange={handleChangeOfInputsSignUp} />
							// 						<label htmlFor="twitter" className="signInUpLabel">Twitter (usuario sin @)  [OPCIONAL]</label>
							// 					</div>
							// 					<div className="signInUpInput">
							// 						<input type="text" value={signUpCredentials.tiktok} name="tiktok" id="tiktok" placeholder="" onChange={handleChangeOfInputsSignUp} />
							// 						<label htmlFor="tiktok" className="signInUpLabel">TikTok (usuario sin @)  [OPCIONAL]</label>
							// 					</div>
							// 				</div>
							// 				<h3>Pasos completados: 4 de 7</h3>
							// 			</div>
							// 		)
							case 4: 
								return (
									<div className='signInUpContent'>
										<h2>Criterios de busqueda</h2>
										<span>Introduce tu género y orienzación sexual para encontrar matches en función de dichos criterios</span>
										<span></span><span></span>
										<div className='signInUpInputs'>
											<div className="signInUpInput">
												<select value={signUpCredentials.gender} name="gender" id="gender" onChange={handleChangeOfSelectsSignUp} required>
													<option value="" disabled hidden>Selecciona una opción</option>
													<option value="male">Hombre</option>
													<option value="female">Mujer</option>
												</select>
												<label htmlFor="gender" className="signInUpLabel">Género</label>
												<label htmlFor="gender" className={`${signUpErrors.includes(NO_GENDER) ? 'signInUpLabelError' : 'signInUpLabelNotError'}`}>{`Error: ${NO_GENDER}`}</label>
											</div>
											<div className="signInUpInput">
												<select value={signUpCredentials.sexualOrientation} name="sexualOrientation" id="sexualOrientation" onChange={handleChangeOfSelectsSignUp} required>
													<option value="" disabled hidden>Selecciona una opción</option>
													<option value="heterosexual">Heterosexual</option>
													<option value="homosexual">Homosexual</option>
													<option value="bisexual">Bisexual</option>
												</select>
												<label htmlFor="sexualOrientation" className="signInUpLabel">Orientación sexual</label>
												<label htmlFor="sexualOrientation" className={`${signUpErrors.includes(NO_SEXUAL_ORIENTATION) ? 'signInUpLabelError' : 'signInUpLabelNotError'}`}>{`Error: ${NO_SEXUAL_ORIENTATION}`}</label>
											</div>
										</div>
										<h3>Pasos completados: 4 de 6</h3>
									</div>
								)
							case 5: 
								return (
									<div className='signInUpContent'>
										<h2>Criterios de busqueda</h2>
										<span>Introduce tu fecha de nacimiento y el rango de edades deseado para tus matches</span>
										<span></span><span></span>
										<div className='signInUpInputs'>
											<div className="signInUpInput">
												{/* <input type="date" value={signUpCredentials.age.toISOString().split('T')[0]} name="age" id="age" onChange={handleChangeOfInputsSignUp} required /> */}
												<DatePicker
													selected={signUpCredentials.age}
													onChange={handleDateChange}
													dateFormat="dd/MM/yyyy"
													showYearDropdown
													showMonthDropdown
													dropdownMode="select"
												/>
												<label htmlFor="age" className="signInUpLabel signInUpLabel-focus">Fecha de nacimiento</label>
												<label htmlFor="age" className={`${signUpErrors.includes(NO_DATE) ? 'signInUpLabelError' : 'signInUpLabelNotError'}`}>{`Error: ${NO_DATE}`}</label>
												<label htmlFor="age" className={`${signUpErrors.includes(NO_AGE) && !signUpErrors.includes(NO_DATE) ? 'signInUpLabelError' : 'signInUpLabelNotError'}`}>{`Error: ${NO_AGE}`}</label>
											</div>
											<div className="signInUpInput-Aux">
												<div className="signInUpInput-Half">
													<select value={signUpCredentials.ageRangeMin} name='ageRangeMin' id='ageRangeMin' onChange={handleChangeOfSelectsSignUp}>
														{ageOptions('min', 18, 50)}
													</select>
													<label htmlFor="ageRangeMin" className="signInUpLabel">Edad min de interés</label>
												</div>
												<div className="signInUpInput-Half">
													<select value={signUpCredentials.ageRangeMax} name='ageRangeMax' id='ageRangeMax' onChange={handleChangeOfSelectsSignUp}>
														{ageOptions('max', +signUpCredentials.ageRangeMin, 50)}
													</select>
													<label htmlFor="ageRangeMax" className="signInUpLabel">Edad max de interés</label>
												</div>
											</div>
										</div>
										<h3>Pasos completados: 5 de 6</h3>
									</div>
								)
							case 6: 
								return (
									<div className='signInUpContent'>
										<h2>Criterios de busqueda</h2>
										<span>Introduce tu zona de residencia y la zona de residencia para tus matches</span>
										<span></span><span></span>
										<div className='signInUpInputs'>
											<div className="signInUpInput">
												<select value={signUpCredentials.area} name="area" id="area" onChange={handleChangeOfSelectsSignUp} required>
													<option value="" disabled hidden>Selecciona una opción</option>
													<option value="west">Madrid Oeste</option>
													<option value="east">Madrid Este</option>
													<option value="center">Madrid Centro</option>
													<option value="north">Madrid Norte</option>
													<option value="south">Madrid Sur</option>
												</select>
												<label htmlFor="area" className="signInUpLabel">Zona de residencia</label>
												<label htmlFor="area" className={`${signUpErrors.includes(NO_AREA) ? 'signInUpLabelError' : 'signInUpLabelNotError'}`}>{`Error: ${NO_AREA}`}</label>
											</div>
											<div className="signInUpInput">
												<select value={signUpCredentials.searchArea} name="searchArea" id="searchArea" onChange={handleChangeOfSelectsSignUp} required>
													<option value="" disabled hidden>Selecciona una opción</option>
													<option value="all">Todo Madrid</option>
													<option value="my area">Mi zona</option>
												</select>
												<label htmlFor="searchArea" className="signInUpLabel">Zona de interés</label>
												<label htmlFor="searchArea" className={`${signUpErrors.includes(NO_SEARCH_AREA) ? 'signInUpLabelError' : 'signInUpLabelNotError'}`}>{`Error: ${NO_SEARCH_AREA}`}</label>
											</div>
										</div>
										<h3>Pasos completados: 6 de 6</h3>
									</div>
								)
							case 7: 
								return (
									<div className='signInUpContent'>
										<h2>Protección de Datos</h2>
										<span>RGPD - Aviso Legal y Políticas de Privacidad</span>
										<span></span><span></span>
										<div className="privacyPolicy">
											<div className="privacyPolicyContent">
												<input className='inputCheck' type="checkbox" checked={privacyPolicy} onChange={handlePrivacyPolicy} />
												<label>
													{/* He leído el <a href={privacyPolicyURL} target="_blank" rel="noopener noreferrer">Aviso Legal</a> {' '}
													y las <a href={privacyPolicyURL} target="_blank" rel="noopener noreferrer">Políticas de Privacidad</a> {' '}
													y acepto las <a href={privacyPolicyURL} target="_blank" rel="noopener noreferrer">Políticas de Privacidad</a> */}
													Afirmo haber leído y acepto las <a href={privacyPolicyURL} target="_blank" rel="noopener noreferrer">Políticas de Privacidad de Datos</a> {' '}
												</label>
											</div>
										</div>
										{/* <h3>Políticas de Privacidad</h3> */}
									</div>
								)
						}
					})()}
					<div className="signInUpButtons">
						{signUpStatus === 7
							? <div className='signInUpButton' onClick={nextPageSignUp}><h1>Registrarse</h1></div>
							: null
						}
						{signUpStatus === 1 || signUpStatus === 2 || signUpStatus === 3 || signUpStatus === 4 || signUpStatus === 5 || signUpStatus === 6
							? <div className='signInUpButton' onClick={nextPageSignUp}><h1>Siguiente</h1></div>
							: null
						}
						{signUpStatus === 2 || signUpStatus === 3 || signUpStatus === 4 || signUpStatus === 5 || signUpStatus === 6 || signUpStatus === 7
							? <div className='signInUpButton' onClick={previousPageSignUp}><h1>Anterior</h1></div>
							: null
						}
					</div>

					<div className='signInUpContentMobil'>
						<span>¿Ya tienes una cuenta?</span>
						<div className='signInUpButton-Mobil' onClick={handleSignInClick}><span>Iniciar Sesión</span></div>
					</div>

				</div>
			</div>

			<div className="form-container sign-in-container">
				<div className='signInUpPage signInPage'>
					<div className="signInUpLogo">
						<img src="../img/logo.png" alt="Secondary App logo" />
					</div>
					<div className='signInUpContent'>
						<h2>Inicia sesión</h2>
						<span>Introduce tu dirección de correo electrónico y tu contraseña para iniciar sesión</span>
						<span></span><span></span>
						<div className='signInUpInputs'>
							<div className="signInUpInput">
								<input type="email" value={signInCredentials.email} name="email" id="inEmail" placeholder="" onChange={handleChangeSignIn} required />
								<label htmlFor="inEmail" className="signInUpLabel">Correo electrónico</label>
							</div>
							<div className="signInUpInput">
								<input type="password" value={signInCredentials.password} name="password" id="inPassword" placeholder="" onChange={handleChangeSignIn} required />
								<label htmlFor="inPassword" className="signInUpLabel">Contraseña</label>
							</div>
						</div>
						<div className='forgottenPassword' onClick={recoverUserPassword}><span>¿Has olvidado la contraseña?</span></div>
					</div>
					<div className='signInUpButton' onClick={handleSubmitSignIn}><h1>Iniciar sesión</h1></div>
					
					<div className='signInUpContentMobil'>
						<span>¿Aún no tienes una cuenta?</span>
						<div className='signInUpButton-Mobil' onClick={handleSignUpClick}><span>Regístrate</span></div>
					</div>
					
				</div>
			</div>

			<div className="overlay-container">
				<div className="overlay">
					<div className="overlay-panel overlay-left">
						<h1>Bienvenido de nuevo</h1>
						<p>Para mantenerse conectado con nosotros, inicie sesión con su información personal</p>
						<div className='signInUpButton signInUpButton-Reverse' onClick={handleSignInClick} id="signIn"><h1>Iniciar sesión</h1></div>
					</div>
					<div className="overlay-panel overlay-right">
						<h1>¿No tienes una cuenta?</h1>
						<p>Introduce tus datos personales y comienza tu viaje con nosotros</p>
						<div className='signInUpButton signInUpButton-Reverse' onClick={handleSignUpClick} id="signUp"><h1>Registrarse</h1></div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default SignInUpLayout










// return (
// 	<div className={`container ${isSignUpActive ? 'right-panel-active' : ''}`} id="container">

// 		<div className="form-container sign-up-container">
// 			<form action="#" onSubmit={handleSubmitSignUp}>
// 				<h1>Create Account</h1>
// 				<div className="social-container">
// 					<a href="#" className="social"><FontAwesomeIcon icon={faFacebookF} /></a>
// 					<a href="#" className="social"><FontAwesomeIcon icon={faGooglePlusG} /></a>
// 					<a href="#" className="social"><FontAwesomeIcon icon={faLinkedinIn} /></a>
// 				</div>
// 				<span>or use your email for registration</span>
// 				<div className="input-group3">
// 					<input type="text" value={signUpCredentials.instagram} name="instagram" id="instagram" placeholder="" onChange={handleChangeSignUp} required />
// 					<label htmlFor="instagram" className="input-group3_label">Instagram</label>
// 				</div>
// 				<div className="input-group3">
// 					<input type="email" value={signUpCredentials.email} name="email" id="email" placeholder="" onChange={handleChangeSignUp} required />
// 					<label htmlFor="email" className="input-group3_label">Email</label>
// 				</div>
// 				<div className="input-group3">
// 					<input type="password" value={signUpCredentials.password} name="password" id="password" placeholder="" onChange={handleChangeSignUp} required />
// 					<label htmlFor="password" className="input-group3_label">Password</label>
// 				</div>
// 				<button>Sign Up</button>
// 			</form>
// 		</div>

// 		<div className="form-container sign-in-container">
// 			<form action="#" onSubmit={handleSubmitSignIn}>
// 				<h1>Sign In</h1>
// 				<div className="social-container">
// 					<a href="#" className="social"><FontAwesomeIcon icon={faFacebookF} /></a>
// 					<a href="#" className="social"><FontAwesomeIcon icon={faGooglePlusG} /></a>
// 					<a href="#" className="social"><FontAwesomeIcon icon={faLinkedinIn} /></a>
// 				</div>
// 				<span>or use your acount</span>
// 				<div className="input-group3">
// 					<input type="email" value={signInCredentials.email} name="email" id="email2" placeholder="" onChange={handleChangeSignIn} required />
// 					<label htmlFor="email2" className="input-group3_label">Email</label>
// 				</div>
// 				<div className="input-group3">
// 					<input type="password" value={signInCredentials.password} name="password" id="password2" placeholder="" onChange={handleChangeSignIn} required />
// 					<label htmlFor="password2" className="input-group3_label">Password</label>
// 				</div>
// 				<a href="#">Forgot your password?</a>
// 				<button>Sign In</button>
// 				{/* <p>{signInError ? "Invalid email or password. Please try again." : ""}</p> */}
// 			</form>
// 		</div>

// 		<div className="overlay-container">
// 			<div className="overlay">
// 				<div className="overlay-panel overlay-left">
// 					<h1>Welcome Back</h1>
// 					<p>To keep connected with us please login with your personal info</p>
// 					<button className="ghost" onClick={handleSignInClick} id="signIn">Sign In</button>
// 				</div>
// 				<div className="overlay-panel overlay-right">
// 					<h1>Don't have an account?</h1>
// 					<p>Enter your personal details and start journey with us</p>
// 					<button className="ghost" onClick={handleSignUpClick} id="signUp">Sign Up</button>
// 				</div>
// 			</div>
// 		</div>

// 	</div>
// )
// }
