import React, { useState } from 'react'

import { useLocation, useNavigate } from 'react-router-dom'

import { Test, Question, QuestionsForTest, AnswersToTest } from "../types"
import QuestionForTest from "../components/QuestionForTests"

import { useQuery, useMutation } from '@apollo/client'
import { GET_QUESTIONS, ADD_ANSWERS, UPDATE_ANSWERS, GET_ANSWERS_BY_USER } from '../graphql/queries'

import Swal from 'sweetalert2'

import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode, Scrollbar, Mousewheel } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/free-mode'
import 'swiper/css/scrollbar'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'

import { FetchError } from '../components/FetchError'

import '../App.css'


function AppCompatibilityTestLayout() {
	// console.log("Entrando en AppCompatibilityTestLayout")

	const navigate = useNavigate()

	const URLParams = new URLSearchParams(useLocation().search)

	let test: Test | null = null
	const testInURLParams = URLParams.get("test")
	if (testInURLParams) test = (JSON.parse(decodeURIComponent(testInURLParams))) as Test

	let answersToTest: AnswersToTest = {id: null, idUser: null, idTest: null, answers: [''], status: ''} as AnswersToTest
	const answersToTestInURLParams = URLParams.get("answersToTest")
	if (answersToTestInURLParams) answersToTest = (JSON.parse(decodeURIComponent(answersToTestInURLParams))) as AnswersToTest

	const { data: dataQuestionsForTest, error: errorQuestionsForTest, loading: loadingQuestionsForTest } = useQuery(GET_QUESTIONS, {variables: {id: test?.idQuestionsForTest}})
	const questionsForTest: QuestionsForTest = dataQuestionsForTest?.getQuestionsForTest as QuestionsForTest

	const questions: Question[] = questionsForTest?.questions
	const [ answers, setAnswers ] = useState<string[]> (answersToTest?.answers)
	// const [answers, setAnswers] = useState<string[]> (Array(questionsForTest?.numOfQuestions).fill(''))

	const [ addAnswersToTest ] = useMutation(ADD_ANSWERS, {refetchQueries: [{query: GET_ANSWERS_BY_USER}]})
	const [ updateAnswersToTest ] = useMutation(UPDATE_ANSWERS)

	const [error, setError] = useState<boolean> (false)

	if (loadingQuestionsForTest) return <div className='app-loading'><h1>Cargando...</h1></div>
	if (errorQuestionsForTest) return <FetchError />

	if (error === true) return <FetchError />


	const handleSubmit = async () => {
		// numOfQuestions === numOfAnswer
		if (questionsForTest?.numOfQuestions === answers.reduce((acc, answer) => (typeof answer === 'string' ? acc + 1 : acc), 0)) {
			try {
				if (answersToTest.id === null) {
					await addAnswersToTest({variables: {idTest: test?.id, answers: answers, otherAnswers: null}})
				}
				else {
					await updateAnswersToTest({variables: {id: answersToTest.id, answers: answers, otherAnswers: null}})
				}

				Swal.fire({
					icon: 'success',
					title: 'Test de compatibilidad completado correctamente',
	
					showCloseButton: window.innerWidth <= 1024,
					showConfirmButton: false,
	
					color: '#e6e8ec',
					background: '#000000',
	
					toast: true,
					position: 'top-end',
					timer: 4000,
				})
				
				navigate(-1)

			} catch (error) { setError(true) }	
		}
		else {
			Swal.fire({
				icon: 'error',
				title: 'Complete las preguntas para enviar el formulario',

				showCloseButton: window.innerWidth <= 1024,
				showConfirmButton: false,

        color: '#e6e8ec',
        background: '#000000',

				toast: true,
				position: 'top-end',
				timer: 4000,
			})
		}	
  }

	const handleAnswerChange = (index: number, answer: string) => {
    const newAnswers = [...answers]
    newAnswers[index] = answer
    setAnswers(newAnswers)
  }

	const flyer = window.innerWidth <= 1024 ? 'mobil' : 'pc'
	

	return (
		<div className='contentAux'>
			<div className='content'>		
				<div className='content-background'>
					<img src={`../../img/${test?.background}_${flyer}_test.png`} alt="questions background" />
				</div>

				<div className='content-header content-header-pad'>
					<h1>{`${test?.name}`}</h1>
				</div>

				<Swiper id='swiperCompabilityTest'
					direction={'vertical'}
					slidesPerView={'auto'}
					autoHeight={true}

					freeMode={true}
					cssMode={false}

					scrollbar={{
						draggable: true,
					}}

					mousewheel={true}

					modules={[FreeMode, Scrollbar, Mousewheel]}
				>
					<SwiperSlide key={`${test?.id}${questionsForTest?.id}`}>
						<div className="questions">
							{questions?.map((question, questionIndex) => (
								<QuestionForTest 
									key={questionIndex}
									index={questionIndex}
									question={question}
									selectedAnswer={answers[questionIndex]}
									onAnswerChange={handleAnswerChange}
								/>
							))}
						</div>
					</SwiperSlide>	
				</Swiper>			

				<div className='swiper-footer'>
					{/* <div className="swiper-footer-content">
						<p>Test de compatibilidad</p>
					</div> */}

					<div className='swiper-button-send' onClick={handleSubmit}>
						<div className='app-section-userSettings-icon'><FontAwesomeIcon icon={faPaperPlane} size="xl"/></div>
						<h1>Enviar test</h1>
					</div>

					{/* <div><FontAwesomeIcon icon={faRightLong} size="2xl"/></div>
					<div><FontAwesomeIcon icon={faArrowRightLong} size="2xl"/></div> */}
				</div>
			</div>
			<div className='urlInstagram urlInstagramAux'>
				<a href={`https://www.instagram.com/unihell.madrid`} target='_blank' rel='noopener noreferrer'><div><div><FontAwesomeIcon className='instagramIcon' icon={faInstagram} size="xl" /></div><h3>@unihell.madrid</h3></div></a>
			</div>
		</div>
	)
}

export default AppCompatibilityTestLayout
