import React from 'react'

import { useRouteError } from "react-router-dom"

import '../App.css'


function ReactRouterError() {

	const error = useRouteError()

	return (
		<div>
			{error.status === 404
				?	<div className='app-error-navbar'> 
						<h1>{error.status}</h1>
						<h2>{error.statusText}</h2>
						<p><i>La URL solicitada no se encontró en este servidor.</i></p>
					</div>
				:	<div className='app-error-navbar'>
						<h1>¡¡Opps... ha ocurrido un error inesperado!!</h1>
						<h3><i>{`${error.status}: ${error.statusText}`}</i></h3>
						<p><i>{`${error.error.message}`}</i></p>
					</div>
			}
		</div>
	)    
}

export default ReactRouterError
